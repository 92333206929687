import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import { AngleDownIcon, CollapseMenuIcon, LogoColorWithText } from '../../icons';
import { device } from '../../util';

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 4px 11px 0px rgba(0, 0, 0, 0.05);
  background: #fff;

  @media ${device.mobileM} {
    padding: 12px 16px;
  }

  @media ${device.tablet} {
    padding: 24px 32px;
  }

  @media ${device.laptop} {
    padding: 0px min(69px, 7%);
  }
`;

const HomeButton = styled.div``;

const CenterNavBarWrapper = styled.div`
  flex-direction: row;
  gap: 32px;

  @media ${device.mobileM} {
    display: none;
  }

  @media ${device.tablet} {
    display: none;
  }

  @media ${device.laptop} {
    display: flex;
  }
`;

const RequestDemoButton = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 141px;
  height: 40px;
  background: #2a7fef;
  border: 1px solid #2a7fef;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 6px;
  cursor: pointer;

  @media ${device.mobileM} {
    display: none;
  }

  @media ${device.tablet} {
    display: flex;
  }

  @media ${device.laptop} {
    display: flex;
  }

  &:hover {
    background: #3a7bcf;
  }
`;

const RequestDemoButtonTitle = styled.div`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 20px;
  color: #ffffff;
`;

const RightWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 32px;
`;

const LoginButton = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 91px;
  height: 40px;
  background: #ffffff;
  border: 1px solid #283167;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 6px;
  cursor: pointer;

  @media ${device.mobileM} {
    display: none;
  }

  @media ${device.tablet} {
    display: flex;
  }

  @media ${device.laptop} {
    display: flex;
  }
`;

const LoginButtonTitle = styled.div`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  color: #283167;
`;

const NavbarButton = styled.div`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: #283167;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media ${device.mobileM} {
    padding: 31px 0px;
  }

  @media ${device.tablet} {
    padding: 31px 0px;
  }

  @media ${device.laptop} {
    padding: 31px 0px;
  }
`;

const NavDropDown = styled.div`
  display: none;
  flex-direction: column;
  align-items: flex-start;
  position: absolute;
  top: 64px;
  z-index: 1;
  border-radius: 8px;
  border: 1px solid var(--gray-100, #f2f4f7);
  background: var(--base-white, #fff);
  box-shadow: 0px 4px 6px -2px rgba(16, 24, 40, 0.03), 0px 12px 16px 9px rgba(16, 24, 40, 0.08);
`;

const ProductNavDropDown = styled(NavDropDown)`
  .__product-button:hover & {
    display: flex;
  }
`;

const CompanyNavDropDown = styled(NavDropDown)`
  .__company-button:hover & {
    display: flex;
  }
`;

const NavDropDownItem = styled.div`
  display: flex;
  padding: 20px 16px 16px 32px;
  align-items: center;
  align-self: stretch;

  color: #283167;
  font-family: Open Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */

  width: 192px;

  &:hover {
    background: #f8f8f8;
  }
`;

const ButtonInnerWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 7px;
`;

const DownArrow = styled(AngleDownIcon)`
  width: 15px;
`;

const MenuIcon = styled(CollapseMenuIcon)`
  @media ${device.mobileM} {
    display: block;
  }

  @media ${device.tablet} {
    display: block;
  }

  @media ${device.laptop} {
    display: none;
  }
`;

const NavbarWrapper = styled.div`
  position: sticky;
  top: 0;
  z-index: 999;
`;

const SmallScreenDropDown = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: var(--base-white, #fff);
  box-shadow: 0px 0px 40px 10px rgba(0, 0, 0, 0.16);
  z-index: 999;
  position: absolute;

  @media ${device.mobileM} {
    width: 100%;
  }

  @media ${device.tablet} {
    max-width: 375px;
    position: absolute;
    right: 0;
  }

  @media ${device.laptop} {
    width: 100%;
    display: none;
  }
`;

const SmallScreenDropDownItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 24px;
  width: 100%;
  box-sizing: border-box;

  color: #283167;
  font-family: Open Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  position: relative;

  &:active {
    background: #f8f8f8;
  }
`;

const SubSmallScreenDropDownItem = styled(SmallScreenDropDownItem)`
  padding-left: 40px;
  display: inline-block;
`;

const SubWrapper = styled.div``;

const CompanyIconInstance = styled(LogoColorWithText)`
  width: 220px;
  height: auto;
`;

function Navbar() {
  const [smallScreenDropDownOpen, setSmallScreenDropDownOpen] = useState(false);
  const [productDropDownExpaned, setProductDropDownExpaned] = useState(false);
  const [companyDropDownExpaned, setCompanyDropDownExpaned] = useState(false);
  const dropdownRef = useRef(null);
  const buttonRef = useRef(null);
  const navigate = useNavigate();
  const goTo = (path: string) => {
    navigate(path);
    setSmallScreenDropDownOpen(false);
    setProductDropDownExpaned(false);
    setCompanyDropDownExpaned(false);
  };

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        !(
          (dropdownRef.current && (dropdownRef.current as HTMLElement).contains(event.target as Node)) ||
          (buttonRef.current && (buttonRef.current as HTMLElement).contains(event.target as Node))
        )
      ) {
        setSmallScreenDropDownOpen(false);
        setProductDropDownExpaned(false);
        setCompanyDropDownExpaned(false);
      }

      if (buttonRef.current && (buttonRef.current as HTMLElement).contains(event.target as Node)) {
        setSmallScreenDropDownOpen(!smallScreenDropDownOpen);
        setProductDropDownExpaned(false);
        setCompanyDropDownExpaned(false);
      }
    }
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [smallScreenDropDownOpen]);

  return (
    <NavbarWrapper>
      <HeaderWrapper>
        <HomeButton onClick={() => goTo('/home')}>
          <CompanyIconInstance />
        </HomeButton>
        <CenterNavBarWrapper>
          <NavbarButton onClick={() => goTo('/home')}>Home</NavbarButton>
          <NavbarButton className="__product-button">
            <ButtonInnerWrapper>
              Products
              <DownArrow />
            </ButtonInnerWrapper>
            <ProductNavDropDown>
              <NavDropDownItem onClick={() => goTo('/index')}>PayStandards Index</NavDropDownItem>
              <NavDropDownItem onClick={() => goTo('/audit')}>PayStandards Audit</NavDropDownItem>
              <NavDropDownItem onClick={() => goTo('/dashboard')}>PayStandards Dashboard</NavDropDownItem>
            </ProductNavDropDown>
          </NavbarButton>
          <NavbarButton className="__company-button">
            <ButtonInnerWrapper>
              Company
              <DownArrow />
            </ButtonInnerWrapper>
            <CompanyNavDropDown>
              <NavDropDownItem onClick={() => goTo('/contactus')}>Contact Us</NavDropDownItem>
              <NavDropDownItem onClick={() => goTo('/team')}>Team</NavDropDownItem>
              <NavDropDownItem onClick={() => goTo('/security-privacy')}>Security & Privacy</NavDropDownItem>
              <NavDropDownItem onClick={() => window.open('https://trust.paystandards.com', '_blank')}>
                Trust Center
              </NavDropDownItem>
              <NavDropDownItem onClick={() => goTo('/release-note-changes')}>Release Notes</NavDropDownItem>
            </CompanyNavDropDown>
          </NavbarButton>
        </CenterNavBarWrapper>
        <RightWrapper>
          <LoginButton>
            <LoginButtonTitle
              onClick={() => {
                window.location.href = 'https://login.paystandards.com';
              }}
            >
              Log In
            </LoginButtonTitle>
          </LoginButton>
          <RequestDemoButton onClick={() => goTo('/demo')}>
            <RequestDemoButtonTitle>Request Demo</RequestDemoButtonTitle>
          </RequestDemoButton>
          <MenuIcon ref={buttonRef} />
        </RightWrapper>
      </HeaderWrapper>
      {smallScreenDropDownOpen && (
        <SmallScreenDropDown ref={dropdownRef}>
          <SmallScreenDropDownItem onClick={() => goTo('/home')}>Home</SmallScreenDropDownItem>
          <SmallScreenDropDownItem
            onClick={() => {
              setProductDropDownExpaned(!productDropDownExpaned);
            }}
          >
            Products <DownArrow />
          </SmallScreenDropDownItem>
          {productDropDownExpaned && (
            <SubWrapper>
              <SubSmallScreenDropDownItem onClick={() => goTo('/index')}>PayStandards Index</SubSmallScreenDropDownItem>
              <SubSmallScreenDropDownItem onClick={() => goTo('/audit')}>PayStandards Audit</SubSmallScreenDropDownItem>
              <SubSmallScreenDropDownItem onClick={() => goTo('/dashboard')}>
                PayStandards Dashboard
              </SubSmallScreenDropDownItem>
            </SubWrapper>
          )}
          <SmallScreenDropDownItem
            onClick={() => {
              setCompanyDropDownExpaned(!companyDropDownExpaned);
            }}
          >
            Company
            <DownArrow />
          </SmallScreenDropDownItem>
          {companyDropDownExpaned && (
            <SubWrapper>
              <SubSmallScreenDropDownItem onClick={() => goTo('/contactus')}>Contact Us</SubSmallScreenDropDownItem>
              <SubSmallScreenDropDownItem onClick={() => goTo('/team')}>Team</SubSmallScreenDropDownItem>
              <SubSmallScreenDropDownItem onClick={() => goTo('/security-privacy')}>
                Security & Privacy
              </SubSmallScreenDropDownItem>
              <SubSmallScreenDropDownItem onClick={() => window.open('https://trust.paystandards.com', '_blank')}>
                Trust Center
              </SubSmallScreenDropDownItem>
              <SubSmallScreenDropDownItem onClick={() => goTo('/release-note-changes')}>
                Release Notes
              </SubSmallScreenDropDownItem>
            </SubWrapper>
          )}
        </SmallScreenDropDown>
      )}
    </NavbarWrapper>
  );
}

export default Navbar;
