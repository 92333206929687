import { BrowserRouter, Navigate, Route, Routes, useLocation } from 'react-router-dom';
import styled, { ThemeProvider } from 'styled-components';
import { useEffect } from 'react';
import { defaultTheme } from './theme';
import Home from './components/home';
import SecurityPrivacy from './components/securityPrivacy';
import Footer from './components/footer';
import Demo from './components/demo';
import ContactUs from './components/contactUs';
import Navbar from './components/navbar';
import IndexProduct from './components/index';
import AuditProduct from './components/audit';
import DashBoardProduct from './components/dashboard';
import SecurityPolicy from './components/securityPolicy';
import TermOfService from './components/termOfService';
import ReleaseNote from './components/releaseNote/ReleaseNote';
import Team from './components/team/Team';
import CookiePolicy from './components/cookiePolicy';

const Wrapper = styled.div`
  height: auto;
`;

// TODO add an index route for when nothing is open
// See https://www.robinwieruch.de/react-router-nested-routes/
function App() {
  function ScrollToTop() {
    const { pathname } = useLocation();
    useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);

    return null;
  }

  return (
    <Wrapper>
      <BrowserRouter>
        <ScrollToTop />
        <ThemeProvider theme={defaultTheme}>
          <Navbar />
          <Routes>
            <Route path="/" element={<Navigate to="/home" replace />} />
            <Route path="home" element={<Home />} />
            <Route path="index" element={<IndexProduct />} />
            <Route path="audit" element={<AuditProduct />} />
            <Route path="dashboard" element={<DashBoardProduct />} />
            <Route path="security-privacy" element={<SecurityPrivacy />} />
            <Route path="demo" element={<Demo />} />
            <Route path="contactus" element={<ContactUs />} />
            <Route path="privacy-policy" element={<SecurityPolicy />} />
            <Route path="cookie-policy" element={<CookiePolicy />} />
            <Route path="terms-of-service" element={<TermOfService />} />
            <Route path="release-note-changes" element={<ReleaseNote />} />
            <Route path="team" element={<Team />} />
            <Route path="*" element={<Navigate to="/home" replace />} />
          </Routes>
          <Footer />
        </ThemeProvider>
      </BrowserRouter>
    </Wrapper>
  );
}

export default App;
