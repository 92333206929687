import styled from 'styled-components';
import { device } from '../../util';

const Wrapper = styled.div`
  background: #fff;

  @media ${device.mobileM} {
    padding: 24px 16px;
  }

  @media ${device.tablet} {
    padding: 40px 32px;
  }

  @media ${device.laptop} {
    padding: 96px 69px;
  }
`;

const ReleaseNoteHeader = styled.div`
  color: #2574a9;
  font-family: Open Sans;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 15px; /* 115.385% */
  letter-spacing: 1px;
  text-transform: uppercase;
`;

const Title = styled.h1`
  color: #240642;
  font-family: Open Sans;

  font-style: normal;
  font-weight: 400;
  line-height: 64.4px; /* 115% */

  @media ${device.mobileM} {
    font-size: 35px;
  }

  @media ${device.tablet} {
    font-size: 56px;
  }

  @media ${device.laptop} {
    font-size: 56px;
  }
`;

const Text = styled.div`
  color: #240642;

  font-family: Inter;
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  display: inline;
`;

const BoldBodyText = styled(Text)`
  font-weight: 700;
  font-size: 15px;
`;

const SubTitleText = styled(BoldBodyText)`
  font-size: 16px;
  display: block;
  margin-bottom: 12px;
`;

const SectionBreak = styled.div`
  height: 32px;
`;

const SubSectionBreak = styled.div`
  height: 20px;
`;

const ItalicText = styled(BoldBodyText)`
  font-style: italic;
  font-size: 16px;
`;

const List = styled.ul`
  list-style-type: none;
  padding-left: 10px;
`;

const ListText = styled.li`
  color: #240642;
  font-family: Inter;
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;

  &::before {
    content: '• ';
  }
`;

function CookiePolicy() {
  return (
    <Wrapper>
      <ReleaseNoteHeader>PAYSTANDARDS Release Notes</ReleaseNoteHeader>
      <Title>PayStandards Cookie Policy</Title>
      <BoldBodyText>
        <ItalicText>Last revised:</ItalicText>{' '}
      </BoldBodyText>
      <Text>June 15, 2023</Text>
      <SectionBreak />
      <SubTitleText>Table of Contents</SubTitleText>{' '}
      <List>
        <ListText>Introduction</ListText>
        <ListText>What is a cookie?</ListText>
        <ListText>Does PayStandards Inc. use cookies?</ListText>
        <ListText>How is PayStandards Inc. using cookies?</ListText>
        <ListText>How are cookies used for advertising purposes?</ListText>
        <ListText>What can you do if you don’t want cookies to be set or want them to be removed?</ListText>
        <ListText>Does PayStandards Inc. respond to Do Not Track Signals?</ListText>
        <ListText>How To Control and Delete Cookies</ListText>
      </List>
      <SectionBreak />
      <SubTitleText>Introduction</SubTitleText>
      <Text>
        This policy provides information about how and when PayStandards Inc. uses cookies. Capitalized terms used in
        this policy but not defined have the meaning set forth in our Privacy Policy, which also includes additional
        details about the collection and use of information at PayStandards Inc.
      </Text>
      <SectionBreak />
      <SubTitleText>What is a cookie?</SubTitleText>
      <Text>
        Cookies are small text files sent by us to your computer or mobile device, which enable PayStandards features
        and functionality. They are unique to your account or your browser. Session-based cookies last only while your
        browser is open and are automatically deleted when you close your browser or your session ends. Persistent
        cookies last until you or your browser delete them or until they expire.
      </Text>
      <SectionBreak />
      <SubTitleText>Does PayStandards Inc. use cookies?</SubTitleText>
      <Text>
        Yes, PayStandards Inc. uses cookies and similar technologies. PayStandards Inc. uses both sessionbased and
        persistent cookies. PayStandards Inc. sets and accesses cookies on the domains operated by PayStandards Inc. and
        its corporate affiliates (collectively, the “Sites”). In addition, PayStandards Inc. uses third party cookies,
        like Google Analytics.
      </Text>
      <SectionBreak />
      <SubTitleText>How is PayStandards Inc. using cookies?</SubTitleText>
      <Text>
        Some cookies are associated with your account and personal information to remember that you are logged in and
        which workspaces you are logged into. Other cookies are not tied to your account but are unique and allow us to
        carry out analytics and customization, among other similar things. Cookies can be used to recognize you when you
        visit a Site or use our Services, remember your preferences, and give you a personalized experience that is
        consistent with your settings. Cookies also make your interactions faster and more secure.
        <SubSectionBreak />
        Categories of use
        <List>
          <ListText>
            <BoldBodyText>Authentication: </BoldBodyText>If you’re signed into the Services, cookies help PayStandards
            Inc. show you the right right information and personalize your experience.
          </ListText>
          <ListText>
            <BoldBodyText>Security: </BoldBodyText>PayStandards Inc. uses cookies to enable and support security
            features, and to help detect malicious activity.
          </ListText>
          <ListText>
            <BoldBodyText>Preferences, features, and services: </BoldBodyText>
            Cookies denote which language you prefer and what your communications preferences are. They can help fill
            out forms on our Sites more easily. They also provide you with features, insights, and customized content.
          </ListText>
          <ListText>
            <BoldBodyText>Marketing: </BoldBodyText>
            PayStandards Inc. may use cookies to help deliver marketing campaigns and track their performance (e.g., a
            user visited PayStandards.com and then made a purchase). Similarly, PayStandards Inc.’s partners may use
            cookies to provide us with information about your interactions with their services, but use of those
            third-party cookies would be subject to the service provider’s policies.
          </ListText>
          <ListText>
            <BoldBodyText>Performance, Analytics, and Research: </BoldBodyText>
            Cookies help PayStandards Inc. learn how well the Sites and Services perform. PayStandards Inc. also uses
            cookies to understand, improve, and research products, features, and services, including to create logs and
            record when you access our Sites and Services from different devices, such as your work computer or your
            mobile device.
          </ListText>
        </List>
      </Text>
      <SectionBreak />
      <SubTitleText>How are cookies used for advertising purposes?</SubTitleText>
      <Text>
        Cookies and other ad technology help PayStandards Inc. market more effectively to users that may be interested
        in the Services. They also help with aggregated auditing, research, and reporting.
      </Text>
      <SectionBreak />
      <SubTitleText>What can you do if you don’t want cookies to be set or want them to be removed?</SubTitleText>
      <Text>
        You have the option to disable and delete cookies that may not be necessary for the basic functionality of our
        website. Please note, blocking categories may impact your experience on our website. You may access the Cookie
        Manager at any time in the footer of our website.
      </Text>
      <SectionBreak />
      <SubTitleText>Does PayStandards Inc. respond to Do Not Track Signals?</SubTitleText>
      <Text>
        The Sites and Services do not collect personal information about your online activities over time and across
        third-party websites or online services. Therefore, “do not track” signals transmitted from web browsers do not
        apply to the Sites or Services, and PayStandards Inc. does not alter any data collection and use practices upon
        receipt of such a signal.
      </Text>
      <SectionBreak />
      <SubTitleText>How To Control and Delete Cookies</SubTitleText>
      <Text>
        1. <BoldBodyText>Using Your Browser</BoldBodyText>
        <SubSectionBreak />
        Many of the cookies used on our website and through emails can be enabled or disabled through our consent tool
        or by disabling the cookies through your browser. To disable cookies through your browser, follow the
        instructions usually located within the “Help,” “Tools” or “Edit” menus in your browser. Please note that
        disabling a cookie or category of cookies does not delete the cookie from your browser unless manually completed
        through your browser function.
      </Text>
      <SubSectionBreak />
      <Text>
        2. <BoldBodyText>Cookies Set in the Past</BoldBodyText>
        <SubSectionBreak />
        Collection of your data from our analytics cookies can be deleted. If cookies are deleted, the information
        collected prior to the preference change may still be used. However, we will stop using the disabled cookie to
        collect any further information from your user experience. For our marketing cookie, when a user opts out of
        tracking, a new cookie is placed to prevent users from being tracked.
      </Text>
    </Wrapper>
  );
}

export default CookiePolicy;
