import styled from 'styled-components';
import { device } from '../../util';

const Wrapper = styled.div`
  background: #fff;

  @media ${device.mobileM} {
    padding: 24px 16px;
  }

  @media ${device.tablet} {
    padding: 40px 32px;
  }

  @media ${device.laptop} {
    padding: 96px 69px;
  }
`;

const ReleaseNoteHeader = styled.div`
  color: #2574a9;
  font-family: Open Sans;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 15px; /* 115.385% */
  letter-spacing: 1px;
  text-transform: uppercase;
`;

const Title = styled.h1`
  color: #080707;
  font-family: Open Sans;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 40px; /* 125% */
`;

const Text = styled.div`
  color: #080707;

  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`;

const Date = styled(Text)`
  font-weight: 600;
  font-size: 17px;
  margin-top: 23px;
`;

const Entry = styled.div`
  margin-bottom: 12px;
  display: flex;
  flex-direction: column;
`;

const EntryTitle = styled(Text)`
  color: #2574a9;
  font-size: 15px;
  font-weight: 700;
  line-height: 24px;
`;

const EntryText = styled(Text)`
  font-size: 15px;
  line-height: 24px;
  margin-left: 24px;
`;

const EntriesWrapper = styled.div`
  margin-top: 24px;

  @media ${device.mobileM} {
    margin-bottom: 0;
  }

  @media ${device.tablet} {
    margin-bottom: 0;
  }

  @media ${device.laptop} {
    margin-bottom: 100px;
  }
`;

const notes = [
  ['Secure Login', 'Users can login to the client portal with end-to-end encryption.'],
  [
    'Client Onboarding',
    'Users can indicate detail on their pay practices and performance management prior to the launch of the Audit product.',
  ],
  ['Data Upload', 'Users can upload employee data for analysis. '],
  ['Audit Analytics', 'Users can view both enterprise-level and fine-tuned pay equity analytics.'],
  ['Pay Recommendations', 'Users can view employee-tailored recommendations based on the predicted pay model. '],
  ['Editable Pay Levels', 'Users can make adjustments to the pay recommendations.'],
  ['Remediation Effects', 'Users can review projected effects and costs of pay decisions.'],
  ['Data Download', 'Users can download the pay adjustment data in XLSX or CSV.'],
];

function ReleaseNote() {
  return (
    <Wrapper>
      <ReleaseNoteHeader>PAYSTANDARDS Release Notes</ReleaseNoteHeader>
      <Title>Release Note Changes</Title>
      <Text>Read about changes to the release notes, with the most recent changes first.</Text>
      <Date>November 1, 2023</Date>
      <EntriesWrapper>
        {notes.map((note) => {
          return (
            <Entry>
              <EntryTitle>{note[0]}</EntryTitle>
              <EntryText>{note[1]}</EntryText>
            </Entry>
          );
        })}
      </EntriesWrapper>
    </Wrapper>
  );
}

export default ReleaseNote;
