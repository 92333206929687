import styled from 'styled-components';
import Dropdown from 'react-dropdown';
import { useState } from 'react';
import { useCalendlyEventListener, PopupModal } from 'react-calendly';
import { useNavigate } from 'react-router-dom';
import { device } from '../../util';
import { AngleDownIcon } from '../../icons';

const PageWrapper = styled.div`
  display: flex;
  justify-content: center;

  @media ${device.mobileM} {
    flex-direction: column;
    align-items: center;
    gap: 100px;
    padding: 24px 0;
  }

  @media ${device.tablet} {
    gap: 40px;
    flex-direction: row;
    padding: 40px 0;
  }

  @media ${device.laptop} {
    gap: 80px;
    flex-direction: row;
    padding: 80px 0;
  }
`;

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;

  @media ${device.mobileM} {
    width: 343px;
  }

  @media ${device.tablet} {
    width: min(365px, 40vw);
  }

  @media ${device.laptop} {
    width: min(560px, 40vw);
  }
`;

const DemoWrapper = styled.div`
  border-radius: 50px 50px 50px 0px;
  border: 2px solid #5fa1f7;
  background: #fff;
  display: flex;
  align-items: center;
  flex-direction: column;

  @media ${device.mobileM} {
    padding: 0px 24px;
  }

  @media ${device.tablet} {
    padding: 0px 32px;
  }

  @media ${device.laptop} {
    padding: 0px 48px;
  }
`;

const Text = styled.div`
  font-family: Open Sans;
  font-style: normal;
  line-height: 140%;
`;

const Title = styled(Text)`
  font-weight: 700;
  color: #283167;
  margin-bottom: 32px;

  @media ${device.mobileM} {
    font-size: 32px;
    width: 343px;
  }

  @media ${device.tablet} {
    font-size: 32px;
    width: min(365px, 40vw);
  }

  @media ${device.laptop} {
    font-size: 32px;
    width: min(560px, 40vw);
  }
`;

const TextInputField = styled.textarea`
  border-radius: 8px;
  border: 1px solid #e0f0ff;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  color: var(--gray-500, #667085);
  font-family: Open Sans;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  box-sizing: border-box;
  padding: 10px 14px;
  margin-bottom: 24px;
  resize: none;

  @media ${device.mobileM} {
    width: 343px;
  }

  @media ${device.tablet} {
    width: min(365px, 40vw);
  }

  @media ${device.laptop} {
    width: min(560px, 40vw);
  }
`;

const DropDownWrapper = styled.div`
  & .Dropdown-control {
    display: flex;
    justify-content: space-between;
    border-radius: 8px;
    border: 1px solid #e0f0ff;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    color: var(--gray-500, #667085);
    font-family: Open Sans;
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    box-sizing: border-box;
    padding: 10px 14px;
    margin-bottom: 24px;

    @media ${device.mobileM} {
      width: 343px;
    }

    @media ${device.tablet} {
      width: min(365px, 40vw);
    }

    @media ${device.laptop} {
      width: min(560px, 40vw);
    }
  }

  & .Dropdown-menu {
    position: absolute;
    border-radius: 8px;
    border: 1px solid var(--gray-100, #f2f4f7);
    background: var(--base-white, #fff);
    box-shadow: 0px 4px 6px -2px rgba(16, 24, 40, 0.03), 0px 12px 16px 9px rgba(16, 24, 40, 0.08);
    z-index: 1;
    margin-top: -24px;

    color: #283167;
    font-family: Open Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */

    @media ${device.mobileM} {
      width: 343px;
    }

    @media ${device.tablet} {
      width: min(365px, 40vw);
    }

    @media ${device.laptop} {
      width: min(560px, 40vw);
    }
  }

  & .Dropdown-option {
    padding: 16px 32px;
    border-radius: 8px;

    &:hover {
      background: #f8f8f8;
    }
  }
`;

const SubmitButton = styled(Text)`
  padding: 10px 32px;
  box-sizing: border-box;
  text-align: center;
  color: #fff;
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  border-radius: 6px;
  border: 1px solid #2a7fef;
  background: #2a7fef;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  margin-bottom: 32px;
  cursor: pointer;

  &:hover {
    background-color: #3a7bcf;
  }

  @media ${device.mobileM} {
    width: 343px;
  }

  @media ${device.tablet} {
    width: min(365px, 40vw);
  }

  @media ${device.laptop} {
    width: min(560px, 40vw);
  }
`;

const InfoText = styled(Text)`
  color: #667085;
  font-size: 15px;
  font-weight: 400;
`;

const InfoLink = styled.a`
  color: #2a7fef;
  font-family: Open Sans;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  cursor: pointer;
`;

const DemoText = styled(Text)`
  color: #3a7bcf;
  font-family: Montserrat;
  font-size: 21px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  margin-bottom: 64px;

  @media ${device.mobileM} {
    width: 300px;
  }

  @media ${device.tablet} {
    width: 300px;
  }

  @media ${device.laptop} {
    width: 400px;
  }
`;

const QuoteMark = styled.div`
  color: #dfe2e6;
  font-family: Open Sans;
  font-size: 107px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  margin-top: 64px;
  text-align: left;
  width: 400px;

  @media ${device.mobileM} {
    width: 300px;
  }

  @media ${device.tablet} {
    width: 300px;
  }

  @media ${device.laptop} {
    width: 400px;
  }
`;
const HintText = styled(Text)<{ enable?: boolean; alwaysShown?: boolean }>`
  color: ${(props) => (props.enable ? '#CA1D1C' : 'var(--gray-500, #667085)')};
  font-style: ${(props) => (props.enable ? 'italic' : 'normal')};
  font-size: 15px;
  margin-top: -18px;
  margin-bottom: 24px;
  display: ${(props) => (props.enable || props.alwaysShown ? 'block' : 'none')};
`;

const DownArrow = styled(AngleDownIcon)`
  width: 16px;
`;
function Demo() {
  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [employeeCount, setEmployeeCount] = useState('');
  const [hintEnable, setHintEnable] = useState([false, false, false, false, false]);
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();
  const goTo = (path: string) => {
    navigate(path);
  };

  useCalendlyEventListener({
    onEventScheduled: () => {}, // TO-DO: add customized confirmation page?
  });

  const checkAllFields = () => {
    const table = [false, false, false, false, false];
    if (email === '') {
      table[0] = true;
    }
    if (firstName === '') {
      table[1] = true;
    }
    if (lastName === '') {
      table[2] = true;
    }
    if (companyName === '') {
      table[3] = true;
    }
    if (employeeCount === '') {
      table[4] = true;
    }
    setHintEnable(table);

    if (table.every((value) => value === false)) {
      setShowModal(true);
    }
  };

  const dropdownOptions = ['1-500', '501-2,500', '10,001-25,000', '25,001-50,000', '50,001-100,000', '100,001+'];

  return (
    <PageWrapper>
      <FormWrapper>
        <Title>Schedule your personalized demo.</Title>
        <TextInputField rows={1} placeholder="Work email*" value={email} onChange={(e) => setEmail(e.target.value)} />
        <HintText enable={hintEnable[0]}>Please complete this required field.</HintText>
        <TextInputField
          rows={1}
          placeholder="First name*"
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
        />
        <HintText enable={hintEnable[1]}>Please complete this required field.</HintText>
        <TextInputField
          rows={1}
          placeholder="Last name*"
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
        />
        <HintText enable={hintEnable[2]}>Please complete this required field.</HintText>
        <TextInputField
          rows={1}
          placeholder="Company name*"
          value={companyName}
          onChange={(e) => setCompanyName(e.target.value)}
        />
        <HintText enable={hintEnable[3]}>Please complete this required field.</HintText>
        <DropDownWrapper>
          <Dropdown
            options={dropdownOptions}
            value={employeeCount}
            onChange={(e) => setEmployeeCount(e.value)}
            placeholder="Employee count*"
            arrowClosed={<DownArrow />}
            arrowOpen={<DownArrow />}
          />
        </DropDownWrapper>
        <HintText alwaysShown enable={hintEnable[4]}>
          Please select an option from the dropdown menu.
        </HintText>
        <SubmitButton onClick={checkAllFields}>Schedule Demo</SubmitButton>
        <PopupModal
          open={showModal}
          onModalClose={() => setShowModal(false)}
          url="https://calendly.com/paystandards/demo?hide_gdpr_banner=1&hide_event_type_details=1&hide_landing_page_details=1"
          rootElement={document.getElementById('root') as HTMLElement}
          prefill={{
            email,
            firstName,
            lastName,
            customAnswers: {
              a1: companyName,
              a2: employeeCount,
            },
          }}
        />
        <InfoText>
          By submitting the form you agree to the PayStandards{' '}
          <InfoLink onClick={() => goTo('/terms-of-service')}>terms of use</InfoLink> and{' '}
          <InfoLink>master service agreement</InfoLink>.
        </InfoText>
      </FormWrapper>
      <DemoWrapper>
        <QuoteMark>“</QuoteMark>
        <DemoText>
          PayStandards exists to help companies reward their people more effectively — boosting employee engagement and
          retention, enhancing employer reputation, and improving corporate performance.{' '}
        </DemoText>
        <DemoText>
          We believe that equitable pay is effective pay, and build the tools companies need to optimize their pay
          equity practices.
        </DemoText>
      </DemoWrapper>
    </PageWrapper>
  );
}

export default Demo;
