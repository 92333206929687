import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { useRef, useState } from 'react';
import { Swiper, SwiperRef, SwiperSlide } from 'swiper/react';
import { Keyboard } from 'swiper/modules';
import { device } from '../../util';
import {
  HassanEnayatiPic,
  JoeDavolioPic,
  KevinHallockPic,
  ToddGershkowitzPic,
  LinkedinIcon,
  GreyCross,
} from '../../icons';
import 'swiper/css';
import 'swiper/css/keyboard';

const Wrapper = styled.div`
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: 100%;

  @media ${device.mobileM} {
    padding: 24px 16px;
  }

  @media ${device.tablet} {
    padding: 40px 32px;
  }

  @media ${device.laptop} {
    padding: 80px 69px;
  }
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  @media ${device.mobileM} {
    gap: 40px;
  }

  @media ${device.tablet} {
    gap: 64px;
  }

  @media ${device.laptop} {
    gap: 80px;
  }
`;

const Title = styled.div`
  color: #283167;
  text-align: center;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  text-wrap: nowrap;

  @media ${device.mobileM} {
    font-size: min(24px, 6vw);
  }

  @media ${device.tablet} {
    font-size: 42px;
  }

  @media ${device.laptop} {
    font-size: 48px;
  }
`;

const SubTitle = styled(Title)`
  @media ${device.mobileM} {
    font-size: 20px;
  }

  @media ${device.tablet} {
    font-size: 32px;
  }

  @media ${device.laptop} {
    font-size: 36px;
  }

  margin-bottom: 32px;
`;

const NameCardWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  cursor: pointer;

  @media ${device.mobileM} {
    gap: 24px;
    width: 100%;
    flex-direction: column;
  }

  @media ${device.tablet} {
    gap: 24px;
    width: 100%;
    flex-direction: row;
  }

  @media ${device.laptop} {
    gap: 24px;
    flex-direction: row;
    width: auto;
  }
`;

const NameCard = styled.div`
  display: flex;
  padding: 24px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
  flex: 1;

  border-radius: 20px;
  background: #fff;
  box-shadow: 0px 0px 50.84746px 0px rgba(1, 40, 90, 0.1);

  box-sizing: border-box;

  @media ${device.mobileM} {
    max-width: min(300px, calc(100% - 32px));
    min-width: min(300px, calc(100% - 32px));
  }

  @media ${device.tablet} {
    max-width: 300px;
    min-width: 300px;
  }

  @media ${device.laptop} {
    max-width: 300px;
    min-width: 300px;
  }
`;

const NameCardName = styled.div`
  color: #273266;
  text-align: center;
  font-family: Open Sans;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  text-wrap: nowrap;
`;

const NameCardTitle = styled.div`
  color: #273266;
  text-align: center;
  font-family: Open Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 140%;
  text-wrap: nowrap;
`;

const NameCardOuterWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  @media ${device.mobileM} {
    flex-direction: column;
    gap: 24px;
  }

  @media ${device.tablet} {
    flex-direction: column;
    gap: 24px;
  }

  @media ${device.laptop} {
    flex-direction: row;
    gap: 24px;
    flex-wrap: wrap;
  }
`;

const LearnMoreWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  wdith: 100%;
  gap: 20px;

  @media ${device.mobileM} {
    margin-top: 40px;
    align-self: center;
    align-items: center;
  }

  @media ${device.tablet} {
    margin-top: 64px;
    align-self: center;
    align-items: center;
  }

  @media ${device.laptop} {
    margin-top: 80px;
    align-self: flex-start;
    align-items: start;
  }
`;

const LearnMoreTitle = styled.div`
  color: #283167;
  font-family: Open Sans;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; /* 33.6px */
`;

const LearnMoreInfo = styled.div`
  color: #283167;
  font-family: Open Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 22.4px */
`;

const LearnMoreButton = styled.div`
  display: flex;
  width: 160px;
  padding: 10px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 6px;
  border: 1px solid #2a7fef;
  background: #2a7fef;

  /* Shadow/xs */
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

  color: var(--base-white, #fff);
  font-family: Open Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; /* 22.4px */

  &:hover {
    background-color: #3a7bcf;
  }

  cursor: pointer;
`;

const ProfileImg = styled.img``;

const ModalWrapper = styled.div`
  display: flex;
  padding: 32px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;

  border-radius: 12px;
  background: #fff;
  box-shadow: 0px 0px 50.84746px 0px rgba(1, 40, 90, 0.1);
  width: 60vw;
  height: 100%;

  box-sizing: border-box;

  @media ${device.mobileM} {
    width: 90vw;
  }

  @media ${device.tablet} {
    width: 60vw;
  }

  @media ${device.laptop} {
    width: 50vw;
  }
`;

const PicNameWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 20px;

  @media ${device.mobileM} {
    flex-direction: column;
  }

  @media ${device.tablet} {
    flex-direction: row;
  }

  @media ${device.laptop} {
    flex-direction: row;
  }
`;

const NameTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
`;

const ModalTitle = styled.div`
  color: #283167;
  text-align: center;
  font-family: Open Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
  margin-top: 24px;
`;

const ModalText = styled.div`
  color: #283167;
  font-family: Open Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 22.4px */
`;
const Divider = styled.div`
  height: 1px;
  background: rgba(0, 0, 0, 0.1);
  width: 100%;
  margin-bottom: 8px;
`;

const SmallProfileImg = styled.img`
  width: 100px;
  height: 100px;
`;

const SwipperWrapper = styled.div`
  width: 100%;

  & .swiper {
    width: 100%;
    height: 100%;
  }

  & .swiper-slide {
    display: flex;
    justify-content: center;
    align-items: center;
    filter: blur(2px);
    align-self: stretch;
    height: auto;
    width: auto;
    cursor: grab;
  }

  & .swiper-slide-active {
    filter: none;
    z-index: 20;
  }
`;

const OverLay = styled.div<{ shown: boolean }>`
  display: ${({ shown }) => (shown ? 'flex' : 'none')};
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* semi-transparent */
  justify-content: center;
  z-index: 1000;
  overflow-y: auto;

  @media ${device.mobileM} {
    padding: 48px 0px;
    align-items: flex-start;
  }

  @media ${device.tablet} {
    align-items: center;
  }

  @media ${device.laptop} {
    align-items: center;
  }
`;

const GreyCrossInstance = styled(GreyCross)`
  cursor: pointer;
  width: 24px;
  height: 24px;
`;

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;

interface TeamInfo {
  name: string;
  title: string;
  bio: string;
  funFact: string;
  picture: string;
  linkedin: string;
}

const teamInfoList = [
  {
    name: 'Joe Davolio',
    title: 'Co-CEO, Founder',
    bio: 'Prior to starting PayStandards, Joe was a Goldman Sachs investment banker, where he advised Technology, Media & Telecommunications  companies. Previously, he built an enterprise software financial technology company and worked at The White House. He graduated from Cornell University with a Bachelor of Science in Industrial & Labor Relations, where he served as student body president and led the largest non-profit of the Ivy League.',
    funFact: 'Joe enjoys playing music gigs around NYC in his free time.',
    picture: JoeDavolioPic,
    linkedin: 'https://www.linkedin.com/in/joedavolio',
  },
  {
    name: 'Todd Gershkowitz',
    title: 'Co-CEO, Founder',
    bio: 'Prior to PayStandards, Todd was Executive Vice President of Global Human Resources at State Street, and was previously an HR executive at several firms, including UBS and GE. He also founded an executive compensation consulting firm which merged with Farient Advisors. Todd  received an M.S. from MIT and a B.S. from Cornell University and is a founding member of the Executives Forum of the Cornell Institute for Compensation Studies. Todd has served on the boards of several private companies and non-profit organizations and currently serves on the board of LineVision, a Series C clean-technology company in Boston.',
    funFact: 'Todd is a jazz aficionado and a seasoned angel investor.',
    picture: ToddGershkowitzPic,
    linkedin: 'https://www.linkedin.com/in/todd-gershkowitz',
  },
  {
    name: 'Hassan Enayati',
    title: 'Chief Data Scientist, Founder',
    bio: 'Hassan is a leading labor economist and a Senior Associate at Abt Associates. He previously served on the research faculty at Cornell University, where he was the Assistant Executive Director of the Cornell Institute for Compensation Studies and a Research Associate within the Yang Tan Institute on Employment and Disability. He earned a Ph.D. in Economics from Michigan State University.',
    funFact: 'Hassan enjoys being outdoors, whether he is golfing or coaching youth sports.',
    picture: HassanEnayatiPic,
    linkedin: 'https://www.linkedin.com/in/hassanenayati',
  },
  {
    name: 'Kevin Hallock',
    title: 'Senior Advisor, Founder',
    bio: 'Kevin is President and Distinguished University Professor of Economics at the University of Richmond. Previously, he was Dean of Cornell University’s SC Johnson College of Business. While at Cornell, Kevin founded the Cornell Institute for Compensation Studies. Kevin is also a National Fellow of the National Academy of Human Resources. He earned a Ph.D. in Economics from Princeton University.',
    funFact:
      'Kevin and his wife own a farm near Richmond where they grow various things including, unfortunately, groundhogs.',
    picture: KevinHallockPic,
    linkedin: 'https://www.linkedin.com/in/kevin-hallock-a276616',
  },
];

interface ProfileModalProps extends TeamInfo {
  onClose: () => void;
}

function ProfileModal(props: ProfileModalProps) {
  const { name, title, bio, funFact, picture, linkedin, onClose } = props;
  return (
    <ModalWrapper>
      <HeaderWrapper>
        <PicNameWrapper>
          <SmallProfileImg src={picture} />
          <NameTitleWrapper>
            <NameCardName>{name}</NameCardName>
            <NameCardTitle>{title}</NameCardTitle>
          </NameTitleWrapper>
        </PicNameWrapper>
        <GreyCrossInstance onClick={onClose} />
      </HeaderWrapper>
      <ModalTitle>About</ModalTitle>
      <Divider />
      <ModalText>{bio}</ModalText>
      <ModalTitle>Fun Fact</ModalTitle>
      <Divider />
      <ModalText>{funFact}</ModalText>
      <br />
      <LinkedinIcon onClick={() => window.open(linkedin, '_blank')} />
    </ModalWrapper>
  );
}

function Team() {
  const [isModalShown, setIsModalShown] = useState(false);
  const swiperRef = useRef<SwiperRef>(null);
  const navigate = useNavigate();
  const goTo = (path: string) => {
    navigate(path);
  };
  const handleContentClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
  };

  return (
    <div>
      <Wrapper>
        <TitleWrapper>
          <Title>
            Our mission is to set the new <br />
            standards for pay
          </Title>
          <SubTitle>Our Leadership</SubTitle>
        </TitleWrapper>
        <div>
          <NameCardOuterWrapper>
            <NameCardWrapper style={{ justifyContent: 'flex-end' }}>
              {teamInfoList.slice(0, 2).map((teamInfo: TeamInfo, index) => (
                <NameCard
                  onClick={() => {
                    setIsModalShown(true);
                    swiperRef.current?.swiper.slideTo(index);
                  }}
                >
                  <ProfileImg src={teamInfo.picture} />
                  <NameCardName>{teamInfo.name}</NameCardName>
                  <NameCardTitle>{teamInfo.title}</NameCardTitle>
                  <LinkedinIcon
                    onClick={(e) => {
                      e.stopPropagation();
                      window.open(teamInfo.linkedin, '_blank');
                    }}
                  />
                </NameCard>
              ))}
            </NameCardWrapper>
            <NameCardWrapper style={{ justifyContent: 'flex-start' }}>
              {teamInfoList.slice(2, 4).map((teamInfo: TeamInfo, index) => (
                <NameCard
                  onClick={() => {
                    setIsModalShown(true);
                    swiperRef.current?.swiper.slideTo(2 + index);
                  }}
                >
                  <ProfileImg src={teamInfo.picture} />
                  <NameCardName>{teamInfo.name}</NameCardName>
                  <NameCardTitle>{teamInfo.title}</NameCardTitle>
                  <LinkedinIcon
                    onClick={(e) => {
                      e.stopPropagation();
                      window.open(teamInfo.linkedin, '_blank');
                    }}
                  />
                </NameCard>
              ))}
            </NameCardWrapper>
          </NameCardOuterWrapper>
          <LearnMoreWrapper>
            <LearnMoreTitle>Our Team is growing!</LearnMoreTitle>
            <LearnMoreInfo>Join us on our journey to set the new standards for pay.</LearnMoreInfo>
            <LearnMoreButton onClick={() => goTo('/contactus')}>Learn More</LearnMoreButton>
          </LearnMoreWrapper>
        </div>
      </Wrapper>
      <OverLay shown={isModalShown} onClick={() => setIsModalShown(false)}>
        <SwipperWrapper onClick={handleContentClick}>
          <Swiper
            initialSlide={Math.floor(Math.random() * 4)}
            modules={[Keyboard]}
            centeredSlides
            slideToClickedSlide
            slidesPerView="auto"
            spaceBetween={30}
            resizeObserver
            updateOnWindowResize
            simulateTouch
            keyboard
            className="mySwiper"
            ref={swiperRef}
          >
            {teamInfoList.map((member) => (
              <SwiperSlide>
                <ProfileModal {...member} onClose={() => setIsModalShown(false)} />
              </SwiperSlide>
            ))}
          </Swiper>
        </SwipperWrapper>
      </OverLay>
    </div>
  );
}

export default Team;
