import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { LinkedInLogo, AicpaSocLogo, LogoWhiteWithText } from '../../icons';
import { device } from '../../util';

const FooterWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  background: #131933;

  @media ${device.mobileM} {
    flex-direction: column-reverse;
    padding: 64px;
  }

  @media ${device.tablet} {
    flex-direction: column-reverse;
    padding: 64px;
  }
  @media ${device.laptop} {
    flex-direction: row;
    padding: 96px;
    justify-content: center;
    gap: 10%;
  }
`;

const CompanyLogoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 7px;

  @media ${device.mobileM} {
    margin-top: 64px;
  }

  @media ${device.tablet} {
    margin-top: 64px;
  }

  @media ${device.laptop} {
    margin-top: 0px;
  }
`;

const FooterLeftWrapper = styled.div`
  display: flex;
  @media ${device.mobileM} {
    flex-direction: column-reverse;
  }

  @media ${device.tablet} {
    flex-direction: column-reverse;
  }

  @media ${device.laptop} {
    flex-direction: column;
  }
`;

const FooterLeftTopWrapper = styled.div`
  display: flex;
  @media ${device.mobileM} {
    flex-direction: column;
    align-items: center;
  }

  @media ${device.tablet} {
    flex-direction: column;
    align-items: center;
  }

  @media ${device.laptop} {
    flex-direction: column;
    align-items: start;
  }
`;

const AicpaSocInstance = styled.img`
  width: 120px;
  height: 120px;
`;

const AicpaSocInfo = styled.div`
  margin-top: 12px;
  margin-bottom: 12px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #ffffff;

  @media ${device.mobileM} {
    width: 327px;
    text-align: center;
  }

  @media ${device.tablet} {
    width: 560px;
    text-align: center;
  }

  @media ${device.laptop} {
    width: 240px;
  }
`;

const CompanyInfo = styled.div`
  width: 137px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #ffffff;

  @media ${device.mobileM} {
    width: fit-content;
  }

  @media ${device.tablet} {
    width: fit-content;
  }

  @media ${device.laptop} {
    width: 137px;
  }
`;

const FooterLeftBottomRowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 86px;
  gap: 24px;

  @media ${device.mobileM} {
    flex-direction: column;
  }

  @media ${device.tablet} {
    flex-direction: column;
  }

  @media ${device.laptop} {
    flex-direction: row;
  }
`;

const FooterRightWrapper = styled.div`
  display: flex;

  @media ${device.mobileM} {
    flex-direction: column-reverse;
    align-items: start;
  }

  @media ${device.tablet} {
    flex-direction: column-reverse;
    align-items: start;
  }

  @media ${device.laptop} {
    flex-direction: column;
    align-items: end;
  }
`;

const FooterRightTopRowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 84px;

  @media ${device.mobileM} {
    flex-wrap: wrap;
  }

  @media ${device.tablet} {
    flex-wrap: nowrap;
  }

  @media ${device.laptop} {
    flex-wrap: nowrap;
  }
`;

const FooterRightTopRowColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const FooterRightTopRowColumnTitle = styled.div`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 32px;
  text-transform: uppercase;
  color: #98a2b3;
`;

const FooterRightTopRowColumnItem = styled.div`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 32px;
  color: #ffffff;
  cursor: pointer;
`;

const FooterRightBottomRowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 25px;
  margin-top: 64px;
  align-items: center;

  @media ${device.mobileM} {
    margin-bottom: 32px;
  }

  @media ${device.tablet} {
    margin-bottom: 32px;
  }

  @media ${device.laptop} {
    margin-bottom: 0px;
  }
`;

const FollowUs = styled.div`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  color: #ffffff;
  text-transform: uppercase;
  cursor: pointer;
`;

const LinkedInLogoInstance = styled(LinkedInLogo)`
  width: 32px;
  height: 32px;
  cursor: pointer;
`;

const FooterLogoInstance = styled(LogoWhiteWithText)`
  width: 140px;
  height: 50px;
`;

const footerItemData: string[][][] = [
  [
    ['Company', ''],
    ['Contact Us', 'contactus'],
    ['Team', 'team'],
    ['Security & Privacy', 'security-privacy'],
    ['Trust Center', ''],
    ['Release Notes', 'release-note-changes'],
  ],
  [
    ['Product', ''],
    ['Index', 'index'],
    ['Audit', 'audit'],
    ['Dashboard', 'dashboard'],
  ],
  [
    ['Legal', ''],
    ['Privacy Policy', 'privacy-policy'],
    ['Terms of Service', 'terms-of-service'],
    ['Master Service Agreement', 'master-service-agreement'],
  ],
];

function Footer() {
  const navigate = useNavigate();
  const goTo = (path: string) => {
    navigate(path);
  };
  return (
    <FooterWrapper>
      <FooterLeftWrapper>
        <FooterLeftTopWrapper>
          <CompanyLogoWrapper>
            <FooterLogoInstance />
          </CompanyLogoWrapper>
          <CompanyInfo>Built in New York, NY © PayStandards, Inc. 2024</CompanyInfo>
        </FooterLeftTopWrapper>
        <FooterLeftBottomRowWrapper>
          <AicpaSocInstance src={AicpaSocLogo} />
          <AicpaSocInfo>
            PayStandards&apos; successful SOC 2 report was issued by Prescient Assurance and is actively monitored by
            Vanta. PayStandards is also GDPR Compliant.
          </AicpaSocInfo>
        </FooterLeftBottomRowWrapper>
      </FooterLeftWrapper>
      <FooterRightWrapper>
        <FooterRightTopRowWrapper>
          {footerItemData.map((footerItemColumn) => (
            <FooterRightTopRowColumnWrapper>
              {footerItemColumn.map((footerItemText, index) =>
                index === 0 ? (
                  <FooterRightTopRowColumnTitle>{footerItemText[0]}</FooterRightTopRowColumnTitle>
                ) : (
                  <FooterRightTopRowColumnItem
                    onClick={
                      footerItemText[0] === 'Trust Center'
                        ? () => window.open('https://trust.paystandards.com', '_blank')
                        : () => goTo(footerItemText[1])
                    }
                  >
                    {footerItemText[0]}
                  </FooterRightTopRowColumnItem>
                ),
              )}
            </FooterRightTopRowColumnWrapper>
          ))}
        </FooterRightTopRowWrapper>
        <FooterRightBottomRowWrapper>
          <FollowUs>FOLLOW US:</FollowUs>
          <LinkedInLogoInstance
            onClick={() => window.open('https://www.linkedin.com/company/paystandards', '_blank')}
          />
        </FooterRightBottomRowWrapper>
      </FooterRightWrapper>
    </FooterWrapper>
  );
}

export default Footer;
