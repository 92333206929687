import styled from 'styled-components';
import { device } from '../../util';
import { DashboardProductImg } from '../../icons';

const Wrapper = styled.div`
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;

  @media ${device.mobileM} {
    padding: 24px 16px;
  }

  @media ${device.tablet} {
    padding: 40px 32px;
  }

  @media ${device.laptop} {
    padding: 80px 69px;
  }
`;
const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
`;

const Title = styled.div`
  color: #283167;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  text-align: center;
  text-wrap: nowrap;

  @media ${device.mobileM} {
    font-size: min(32px, 7vw);
  }

  @media ${device.tablet} {
    font-size: 42px;
  }

  @media ${device.laptop} {
    font-size: 42px;
  }
`;

const BodyText = styled.div`
  color: var(--grey-700, #575d66);
  text-align: center;

  /* Website/P */
  font-family: Open Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  max-width: 840px;
  margin-top: 16px;
`;

// const RequestDemoButton = styled.div`
//   padding: 10px 16px;
//   background: #2a7fef;
//   border: 1px solid #2a7fef;
//   box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
//   border-radius: 6px;
//   cursor: pointer;
//   color: #ffffff;
//   font-weight: 700;
//   font-size: 15px;
//   margin-top: 24px;

//   &:hover {
//     background-color: #3a7bcf;
//   }
// `;

const ImgWrapper = styled.div`
  @media ${device.mobileM} {
    margin-top: 24px;
  }

  @media ${device.tablet} {
    margin-top: 48px;
  }

  @media ${device.laptop} {
    margin-top: 90px;
  }
`;

const ComputerImg = styled(DashboardProductImg)`
  max-width: 100%;
  height: auto;
`;
function DashBoardProduct() {
  // const navigate = useNavigate();
  // const goTo = (path: string) => {
  //   navigate(path);
  // };
  return (
    <Wrapper>
      <TitleWrapper>
        <Title>PayStandards Dashboard&#8482;</Title>
      </TitleWrapper>
      <BodyText>
        SaaS tool to make better everyday pay decisions (including hiring, promotions, and ad hoc salary increases,
        etc.) that sustain pay equity progress.
      </BodyText>
      {/* <RequestDemoButton onClick={() => goTo('/demo')}>Request Demo</RequestDemoButton> */}
      <ImgWrapper>
        <ComputerImg />
      </ImgWrapper>
    </Wrapper>
  );
}

export default DashBoardProduct;
