import styled from 'styled-components';
import { device } from '../../util';
import { IndexProductImgOne, IndexProductImgTwo, IndexProductImgThree } from '../../icons';

const Wrapper = styled.div`
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media ${device.mobileM} {
    padding: 24px 16px;
  }

  @media ${device.tablet} {
    padding: 40px 32px;
  }

  @media ${device.laptop} {
    padding: 80px 69px;
  }
`;
const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const Title = styled.div`
  color: #283167;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  text-align: center;
  text-wrap: nowrap;

  @media ${device.mobileM} {
    font-size: min(32px, 7vw);
  }

  @media ${device.tablet} {
    font-size: 42px;
  }

  @media ${device.laptop} {
    font-size: 42px;
  }
`;

const BodyText = styled.div`
  color: var(--grey-700, #575d66);
  text-align: center;

  /* Website/P */
  font-family: Open Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  max-width: 640px;
  margin-top: 16px;
`;

// const RequestDemoButton = styled.div`
//   padding: 10px 16px;
//   background: #2a7fef;
//   border: 1px solid #2a7fef;
//   box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
//   border-radius: 6px;
//   cursor: pointer;
//   color: #ffffff;
//   font-weight: 700;
//   font-size: 15px;
//   margin-top: 24px;

//   &:hover {
//     background-color: #3a7bcf;
//   }
// `;

const ThreeColWrapper = styled.div`
  display: flex;
  gap: 40px;
  flex-wrap: wrap;
  justify-content: center;

  @media ${device.mobileM} {
    margin-top: 24px;
    flex-direction: column;
  }

  @media ${device.tablet} {
    margin-top: 48px;
    flex-direction: row;
  }

  @media ${device.laptop} {
    margin-top: 100px;
    flex-direction: row;
  }
`;

const ColWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
`;

const ImgTitle = styled.div`
  color: #011344;
  text-align: center;
  font-family: Open Sans;
  font-size: 19.222px;
  font-style: normal;
  font-weight: 700;
  line-height: 25.63px; /* 133.333% */
`;

const ImageBody = styled.div`
  color: #011344;
  font-family: Open Sans;
  font-size: 19.222px;
  font-style: normal;
  font-weight: 400;
  line-height: 25.63px;
  width: 240px;
  text-align: center;
`;

function IndexProduct() {
  // const navigate = useNavigate();
  // const goTo = (path: string) => {
  //   navigate(path);
  // };
  return (
    <Wrapper>
      <TitleWrapper>
        <Title>PayStandards Index&#8482;</Title>
      </TitleWrapper>
      <BodyText>
        Standardized ratings and root-cause analytics that provide a point-in-time assessment of your company’s pay
        equity position.
      </BodyText>
      {/* <RequestDemoButton onClick={() => goTo('/demo')}>Request Demo</RequestDemoButton> */}
      <ThreeColWrapper>
        <ColWrapper>
          <IndexProductImgOne />
          <ImgTitle>Quantify</ImgTitle>
          <ImageBody>company performance into verified metrics</ImageBody>
        </ColWrapper>
        <ColWrapper>
          <IndexProductImgTwo />
          <ImgTitle>Visualize</ImgTitle>
          <ImageBody>pay equity across a company’s workforce</ImageBody>
        </ColWrapper>
        <ColWrapper>
          <IndexProductImgThree />
          <ImgTitle>Assess</ImgTitle>
          <ImageBody>Root-causes to identify specific actions to take</ImageBody>
        </ColWrapper>
      </ThreeColWrapper>
    </Wrapper>
  );
}

export default IndexProduct;
