import axios, { AxiosResponse, AxiosError } from 'axios';

const endpoint = 'https://hc0so5spz2.execute-api.us-east-1.amazonaws.com/prod/';

const sendContactEmail = (
  email: string,
  message: string,
  hearAboutUs: string,
  time: string,
  onSuccess: (res: AxiosResponse) => void,
  onError: (err: AxiosError) => void,
) => {
  axios.post(endpoint, { email, message, hearAboutUs, time }).then(onSuccess).catch(onError);
};

export default sendContactEmail;
