export const defaultTheme = {
  alpha900: '#17375E',
  alpha800: '#275087',
  alpha700: '#3166AB',
  alpha600: '#3A7BCF',
  alpha500: '#2A7FEF',
  alpha400: '#5FA1F7',
  alpha300: '#A6D2FF',
  alpha200: '#D7EAFC',
  alpha150: '#EFF6FF',
  alpha100: '#FAFCFF',
  alpha000: '#FFFFFF',
  bravo900: '#1C1D1F',
  bravo800: '#2F3133',
  bravo700: '#575D66',
  bravo600: '#84878A',
  bravo500: '#CED3D9',
  bravo400: '#DFE2E6',
  bravo300: '#F3F3F7',
  bravo200: '#F9F9FB',
  bravo100: '#FAFBFC',
  charlie100: '#FFEEDB',
  charlie700: '#C36E0E',
  charlie900: '#643807',
  delta100: '#FFE6E5',
  delta800: '#71100F',
  echo100: '#F1FEEB',
  echo700: '#5BA739',
  echo900: '#29441D',
};
export type ThemeType = { [key: string]: string };

export const darkTheme = {};
// ${(props) => props.theme.bravo900}
