import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { device } from '../../util';
import {
  WebHomePageFirstImage,
  WebHomePageSecondImage,
  Medal,
  LightBulb,
  CheckShield,
  MagnifyingGlass,
  Balance,
  StarButton,
} from '../../icons';

const Wrapper = styled.div`
  background: #fff;

  @media ${device.mobileM} {
    padding: 24px 16px;
  }

  @media ${device.tablet} {
    padding: 40px 32px;
  }

  @media ${device.laptop} {
    padding: 96px 69px;
  }
`;
const RowWrapper = styled.div`
  width: 100%;
`;
const CenterWrapper = styled(RowWrapper)`
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;

  @media ${device.mobileM} {
    margin-top: 80px;
  }
  @media ${device.tablet} {
    margin-top: 100px;
  }
  @media ${device.laptop} {
    margin-top: 140px;
  }
`;

const Image = styled.img`
  width: 100%;
`;
const FirstImage = styled(Image)`
  @media ${device.mobileM} {
    min-width: 100%;
  }
  @media ${device.tablet} {
    min-width: 520px;
  }
  @media ${device.laptop} {
    min-width: 520px;
  }
`;

const SecondImage = styled(Image)`
  @media ${device.mobileM} {
    min-width: 100%;
  }
  @media ${device.tablet} {
    min-width: 330px;
  }
  @media ${device.laptop} {
    min-width: 330px;
  }
`;

const Text = styled.div`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  color: #283167;
`;

const BodyText = styled(Text)`
  font-weight: 400;
  font-size: 16px;
  margin-bottom: 32px;
  @media ${device.mobileM} {
    max-width: 100%;
  }
  @media ${device.laptop} {
    max-width: 540px;
  }
`;

const BoldBodyText = styled(Text)`
  display: inline;
  font-size: 16px;
  font-weight: 700;
`;

const TitleText = styled.h1`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  color: #283167;
  margin-bottom: 24px;
  text-wrap: nowrap;

  @media ${device.mobileM} {
    max-width: 100%;
    font-size: min(24px, 5vw);
  }
  @media ${device.tablet} {
    font-size: 40px;
  }
  @media ${device.laptop} {
    max-width: 540px;
    font-size: 48px;
  }
`;

const SubTitleText = styled(Text)`
  text-wrap: nowrap;
  @media ${device.mobileM} {
    font-size: min(32px, 6vw);
    text-align: center;
  }
  @media ${device.tablet} {
    font-size: 40px;
    text-align: left;
  }
  @media ${device.laptop} {
    font-size: 40px;
    text-align: left;
  }
`;

const ThreeColWrapper = styled.div`
  flex: 1;
  @media ${device.mobileM} {
    margin-top: 32px;
  }
  @media ${device.tablet} {
    margin-top: 40px;
  }
  @media ${device.laptop} {
    margin-top: 54px;
  }
`;

const CenterNoMarginWrapper = styled(CenterWrapper)`
  margin-top: 0;
`;
const BlueCenterWrapper = styled(CenterWrapper)`
  background: #f3f9ff;
  box-sizing: border-box;

  @media ${device.mobileM} {
    width: calc(100% + 32px);
    margin-left: -16px;
    padding: 40px 16px;
  }
  @media ${device.tablet} {
    width: calc(100% + 64px);
    margin-left: -32px;
    padding: 40px 32px;
  }
  @media ${device.laptop} {
    padding-top: 80px;
    padding-bottom: 80px;
    width: calc(100% + 138px);
    margin-left: -69px;
    padding: 40px 69px;
  }
`;
const FlexWrapper = styled(RowWrapper)`
  display: flex;
  align-items: center;
  justify-content: center;
  @media ${device.mobileM} {
    flex-direction: column;
    gap: 25px;
  }
  @media ${device.laptop} {
    flex-direction: row;
    gap: 5%;
  }
`;

const ThreeColFlexWrapper = styled.div`
  display: flex;
  justify-content: center;
  @media ${device.mobileM} {
    flex-direction: column;
  }
  @media ${device.tablet} {
    flex-direction: row;
    gap: 32px;
  }
  @media ${device.laptop} {
    flex-direction: row;
  }
`;

const LeftRightFlexWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10%;

  @media ${device.mobileM} {
    margin-top: 40px;
    flex-direction: column;
  }
  @media ${device.tablet} {
    margin-top: 80px;
    flex-direction: row;
  }
  @media ${device.laptop} {
    margin-top: 80px;
    flex-direction: row;
  }
`;

const TwoColWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const RightTwoColWrapper = styled(TwoColWrapper)`
  @media ${device.mobileM} {
    align-items: center;
    text-align: center;
  }
  @media ${device.laptop} {
    align-items: start;
    text-align: start;
  }
`;
const LeftTwoColWrapper = styled(TwoColWrapper)`
  @media ${device.mobileM} {
    align-items: center;
    text-align: center;
  }
  @media ${device.tablet} {
    align-items: start;
    text-align: start;
  }
  @media ${device.laptop} {
    align-items: start;
    text-align: start;
  }
`;

const LeftTwoColWrapperTwo = styled(TwoColWrapper)`
  @media ${device.mobileM} {
    align-items: center;
    text-align: center;
  }
  @media ${device.tablet} {
    align-items: center;
    text-align: center;
  }
  @media ${device.laptop} {
    align-items: start;
    text-align: start;
  }
`;

const SecondaryTitleText = styled(Text)`
  margin-top: 24px;
  text-wrap: nowrap;
  @media ${device.mobileM} {
    height: auto;
    font-size: min(24px, 5vw);
  }
  @media ${device.tablet} {
    height: 68px;
    font-size: min(24px, 2.5vw);
  }
  @media ${device.laptop} {
    height: 68px;
    font-size: min(24px, 5vw);
  }
`;

const ThreeColBodyText = styled(BodyText)`
  height: 68px;
  margin-top: 10px;
  margin-bottom: 16px;
  @media ${device.mobileM} {
    height: auto;
  }
  @media ${device.tablet} {
    height: 68px;
  }
  @media ${device.laptop} {
    height: 68px;
  }
`;

const SecondThreeColBodyText = styled(ThreeColBodyText)`
  @media ${device.tablet} {
    height: 132px;
  }
  @media ${device.laptop} {
    height: 110px;
  }
`;

const LeftRightBodyText = styled(BodyText)`
  margin-top: 24px;
  text-wrap: wrap;
  @media ${device.mobileM} {
    text-align: center;
  }
  @media ${device.tablet} {
    text-align: left;
  }
  @media ${device.laptop} {
    text-align: left;
  }
`;

const CenterBodyText = styled(BodyText)`
  margin-top: 24px;
  max-width: 100%;
  text-wrap: wrap;

  @media ${device.laptop} {
    max-width: 70%;
  }
`;
const RequestDemoButton = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 240px;
  height: 56px;
  background: #2a7fef;
  border: 1px solid #2a7fef;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 6px;
  cursor: pointer;
  color: #ffffff;
  font-weight: 700;
  font-size: 16px;

  &:hover {
    background-color: #3a7bcf;
  }
`;
const LearnMoreText = styled(Text)`
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  color: #2a7fef;
  cursor: pointer;
  @media ${device.mobileM} {
    margin-bottom: 10px;
  }
  @media ${device.laptop} {
    margin-bottom: 0px;
  }
`;
const LearnButtonButton = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-right: auto;
  margin-left: auto;
  padding: 10px 16px;
  background: #2a7fef;
  border: 1px solid #2a7fef;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 6px;
  cursor: pointer;
  color: #ffffff;
  font-weight: 700;
  font-size: 15px;

  &:hover {
    background-color: #3a7bcf;
  }
`;

const MagicLineBreak = styled.br`
  @media ${device.mobileM} {
    display: none;
  }
  @media ${device.laptop} {
    display: block;
  }
`;

const MagicLineBreakTwo = styled.div`
  @media ${device.mobileM} {
    display: none;
  }
  @media ${device.tablet} {
    display: block;
  }
`;
// const NameWrapper = styled.div`
//   @media ${device.mobileM} {
//     margin-top: 64px;
//   }
//   @media ${device.tablet} {
//     margin-top: 100px;
//   }
//   @media ${device.laptop} {
//     margin-top: 120px;
//   }
//   background: #f3f9ff;
//   display: flex;
//   justify-content: center;
//   position: relative;
// `;
// const ArrowWrapper = styled.div`
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   width: 32px;
//   height: 32px;
//   background: #2a7fef;
//   border-radius: 130px;
//   position: absolute;
//   top: 50%;
//   cursor: pointer;
// `;
// const LeftArrowWrapper = styled(ArrowWrapper)`
//   left: -15px;
// `;
// const RightArrowWrapper = styled(ArrowWrapper)`
//   right: -15px;
// `;
// const LeftArrow = styled(SmallLeftArrowIcon)`
//   width: 20px;
//   height: 12px;
//   color: #ffffff;
//   display: block;
// `;
// const RightArrow = styled(SmallRightArrowIcon)`
//   width: 20px;
//   height: 12px;
//   color: #ffffff;
//   display: block;
// `;
// const ProfileWrapper = styled.div`
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   text-align: center;
//   @media ${device.mobileM} {
//     width: 95%;
//   }
//   @media ${device.laptop} {
//     width: 45%;
//   }
//   padding: 62px 0;
// `;
// const NameText = styled(Text)`
//   font-size: 20px;
//   line-height: 100%;
//   margin-top: 17px;
// `;
// const NameTitleText = styled(Text)`
//   font-size: 15px;
//   line-height: 100%;
//   font-weight: 300;
//   margin-top: 16px;
//   margin-bottom: 24px;
// `;
// const ProfilePic = styled(Image)`
//   @media ${device.mobileM} {
//     width: 100px;
//   }
//   @media ${device.laptop} {
//     width: 20%;
//   }
// `;
// const SlideStatusOneInstance = styled(SlideStatusOne)`
//   width: 56px;
//   margin-top: 25px;
// `;
// const SlideStatusTwoInstance = styled(SlideStatusTwo)`
//   width: 56px;
//   margin-top: 25px;
// `;
// const SlideStatusThreeInstance = styled(SlideStatusThree)`
//   width: 56px;
//   margin-top: 25px;
// `;
// const SlideStatusFourInstance = styled(SlideStatusFour)`
//   width: 56px;
//   margin-top: 25px;
// `;
// const SlideStatusFiveInstance = styled(SlideStatusFive)`
//   width: 56px;
//   margin-top: 25px;
// `;

// const SubscribeWrapper = styled.div`
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   margin-top: 50px;
//   margin-bottom: 50px;
//   background: #f8f8f8;
// `;

// const EmailIconInstance = styled(EmailIcon)`
//   width: 64px;
//   height: 64px;
//   margin-top: 40px;
// `;

// const SubscribeTitle = styled.div`
//   display: flex;
//   width: 250px;
//   margin-top: 10px;
//   font-family: Open Sans;
//   font-size: 24px;
//   font-weight: 700;
//   line-height: 34px;
//   letter-spacing: 0em;
//   text-align: center;
//   color: #283167;
// `;

// const SubscribeTextField = styled.div`
//   box-sizing: border-box;
//   display: flex;
//   flex-direction: row;
//   justify-content: center;
//   align-items: flex-start;
//   gap: 10px;
//   width: 295px;
//   height: 40px;
//   background: #ffffff;
//   border: 1px solid #dfe2e6;
//   border-radius: 2px;
//   margin-top: 30px;
// `;

// const SubscribeTextFieldInput = styled.input.attrs({
//   type: 'text',
// })`
//   border: 0;
//   outline: none;
//   width: 95%;
//   height: 90%;
//   font-family: 'Open Sans';
//   font-style: normal;
//   font-weight: 400;
//   font-size: 13px;
//   line-height: 150%;
//   color: #84878a;
// `;

// const SubscribeButton = styled(LearnButtonButton)`
//   width: 295px;
//   margin-top: 20px;
//   margin-bottom: 50px;
// `;

// const FootnoteWrapper = styled.div`
//   color: #011344;
//   font-family: Inter;
//   font-size: 16px;
//   font-style: italic;
//   font-weight: 400;
//   line-height: normal;

//   margin-top: 120px;
// `;

const BlueBoxColWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 32px;

  @media ${device.mobileM} {
    margin-top: 40px;
    flex-direction: column;
  }
  @media ${device.tablet} {
    margin-top: 64px;
    flex-direction: column;
  }
  @media ${device.laptop} {
    margin-top: 64px;
    flex-direction: row;
  }
`;

const BlueBoxWrapper = styled.div`
  display: flex;
  padding: 32px;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  border-radius: 12px;
  border: 2px solid #2a7fef;
  flex: 1;
`;

const BlueBoxTitle = styled(Text)`
  text-align: center;
  font-weight: 700;
  text-wrap: nowrap;

  @media ${device.mobileM} {
    font-size: 24px;
  }
  @media ${device.tablet} {
    font-size: 32px;
  }
  @media ${device.laptop} {
    font-size: 32px;
  }
`;

// interface ProfileProps {
//   slideStatus: number;
// }

// function Profile(props: ProfileProps) {
//   const { slideStatus } = props;
//   if (slideStatus === 0) {
//     return (
//       <ProfileWrapper>
//         <ProfilePic src={ProfilePicTodd} />
//         <NameText>Todd Gershkowitz</NameText>
//         <NameTitleText>Co-CEO, PayStandards</NameTitleText>
//         <BodyText>
//           &quot;At PayStandards, we believe in a world where equal work means equal pay. We exist to drive business
//           outcomes by evening the playing field.&quot;
//         </BodyText>
//         <LinkedinIcon />
//         <SlideStatusOneInstance />
//       </ProfileWrapper>
//     );
//   }
//   if (slideStatus === 1) {
//     return (
//       <ProfileWrapper>
//         <ProfilePic src={ProfilePicKevin} />
//         <NameText>Kevin Hallock</NameText>
//         <NameTitleText>Senior Advisor, PayStandards</NameTitleText>
//         <BodyText>
//           &quot;At PayStandards, we believe in a world where equal work means equal pay. We exist to drive business
//           outcomes by evening the playing field.&quot;
//         </BodyText>
//         <LinkedinIcon />
//         <SlideStatusTwoInstance />
//       </ProfileWrapper>
//     );
//   }
//   if (slideStatus === 2) {
//     return (
//       <ProfileWrapper>
//         <ProfilePic src={ProfilePicHassan} />
//         <NameText>Hassan Enayati</NameText>
//         <NameTitleText>Chief Data Scientist, PayStandards</NameTitleText>
//         <BodyText>
//           &quot;At PayStandards, we believe in a world where equal work means equal pay. We exist to drive business
//           outcomes by evening the playing field.&quot;
//         </BodyText>
//         <LinkedinIcon />
//         <SlideStatusThreeInstance />
//       </ProfileWrapper>
//     );
//   }
//   if (slideStatus === 3) {
//     return (
//       <ProfileWrapper>
//         <ProfilePic src={ProfilePicWaterman} />
//         <NameText>K. Waterman</NameText>
//         <NameTitleText>Advisot, PayStandards</NameTitleText>
//         <BodyText>
//           &quot;At PayStandards, we believe in a world where equal work means equal pay. We exist to drive business
//           outcomes by evening the playing field.&quot;
//         </BodyText>
//         <LinkedinIcon />
//         <SlideStatusFourInstance />
//       </ProfileWrapper>
//     );
//   }
//   return (
//     <ProfileWrapper>
//       <ProfilePic src={ProfilePicJoe} />
//       <NameText>Joe Davolio</NameText>
//       <NameTitleText>Co-CEO, PayStandards</NameTitleText>
//       <BodyText>
//         &quot;At PayStandards, we believe in a world where equal work means equal pay. We exist to drive business
//         outcomes by evening the playing field.&quot;
//       </BodyText>
//       <LinkedinIcon />
//       <SlideStatusFiveInstance />
//     </ProfileWrapper>
//   );
// }

function Home() {
  // const [slideStatus, setSlideStatus] = useState(0);

  // const incSlideStatus = () => {
  //   const newSlideStatus = (slideStatus + 1) % 5 < 0 ? ((slideStatus + 1) % 5) + 5 : (slideStatus + 1) % 5;
  //   setSlideStatus(newSlideStatus);
  // };
  // const decSlideStatus = () => {
  //   const newSlideStatus = (slideStatus - 1) % 5 < 0 ? ((slideStatus - 1) % 5) + 5 : (slideStatus - 1) % 5;
  //   setSlideStatus(newSlideStatus);
  // };

  const navigate = useNavigate();
  const goTo = (path: string) => {
    navigate(path);
  };

  return (
    <Wrapper>
      <FlexWrapper>
        <LeftTwoColWrapperTwo>
          <TitleText>
            Setting the New <MagicLineBreak />
            Standards for Pay.
          </TitleText>
          <BodyText>
            PayStandards software gives you pay recommendations that consider <BoldBodyText>only</BoldBodyText> the
            right things.
          </BodyText>
          <RequestDemoButton onClick={() => goTo('/demo')}>Request Demo</RequestDemoButton>
        </LeftTwoColWrapperTwo>
        <RightTwoColWrapper>
          <FirstImage src={WebHomePageFirstImage} />
        </RightTwoColWrapper>
      </FlexWrapper>
      <LeftRightFlexWrapper>
        <LeftTwoColWrapper>
          <SubTitleText>
            Avoid the Costly
            <MagicLineBreakTwo /> Vicious Cycle
          </SubTitleText>
          <LeftRightBodyText>
            Companies that address pay equity tend to spend considerable sums each year trying, but failing, to achieve
            it. They focus on pay levels of outlier employees at year-end, ignoring the rest of the workforce. While
            this typically improves the pay gap at year-end, workforce changes throughout the following year drive its
            deterioration, culminating in a costly vicious cycle.
          </LeftRightBodyText>
        </LeftTwoColWrapper>
        <RightTwoColWrapper>
          <SecondImage src={WebHomePageSecondImage} />
        </RightTwoColWrapper>
      </LeftRightFlexWrapper>
      <CenterWrapper>
        <SubTitleText>PayStandards Solution</SubTitleText>
        <BlueBoxColWrapper>
          <BlueBoxWrapper>
            <BlueBoxTitle>
              Reduce pay gaps more <br />
              significantly...
            </BlueBoxTitle>
            <BodyText>
              PayStandards outperforms traditional approaches by delivering up to 40% pay gap reduction in the first
              year alone, and accelerates this progress over time.{' '}
            </BodyText>
          </BlueBoxWrapper>
          <BlueBoxWrapper>
            <BlueBoxTitle>
              ...and more
              <br /> cost-effectively
            </BlueBoxTitle>
            <BodyText>
              PayStandards reduces the compensation expense required to address pay equity by up to 70% relative to
              traditional approaches.
            </BodyText>
          </BlueBoxWrapper>
        </BlueBoxColWrapper>
      </CenterWrapper>
      <CenterWrapper>
        <SubTitleText>Product Suite</SubTitleText>
        <ThreeColFlexWrapper>
          <ThreeColWrapper>
            <MagnifyingGlass />
            <SecondaryTitleText>PayStandards Index</SecondaryTitleText>
            <ThreeColBodyText>
              Point-in-time pay equity assessment to take <MagicLineBreak />
              stock of where you stand <BoldBodyText>today</BoldBodyText>.
            </ThreeColBodyText>
            <LearnMoreText onClick={() => goTo('/index')}>Learn More</LearnMoreText>
          </ThreeColWrapper>
          <ThreeColWrapper>
            <Balance />
            <SecondaryTitleText>PayStandards Audit</SecondaryTitleText>
            <ThreeColBodyText>
              Best-in-class resolution of pay gaps with improved pay decisions at <BoldBodyText>year-end</BoldBodyText>.
            </ThreeColBodyText>
            <LearnMoreText onClick={() => goTo('/audit')}>Learn More</LearnMoreText>
          </ThreeColWrapper>
          <ThreeColWrapper>
            <StarButton />
            <SecondaryTitleText>PayStandards Dashboard</SecondaryTitleText>
            <ThreeColBodyText>
              Better day-to-day pay decisions to sustain pay equity progress <BoldBodyText>year-round</BoldBodyText>.
            </ThreeColBodyText>
            <LearnMoreText onClick={() => goTo('/dashboard')}>Learn More</LearnMoreText>
          </ThreeColWrapper>
        </ThreeColFlexWrapper>
      </CenterWrapper>
      <BlueCenterWrapper>
        <SubTitleText>Partner with Us</SubTitleText>
        <CenterBodyText>
          Whether you’re already addressing pay equity and looking for a better outcome, or haven’t started and are
          concerned with the underlying risk — and whether you are a Fortune 500 company or have 1,000 employees —
          PayStandards is your partner.
        </CenterBodyText>
        <LearnButtonButton onClick={() => goTo('/demo')}>Request Demo</LearnButtonButton>
      </BlueCenterWrapper>
      <CenterWrapper>
        <SubTitleText>Why PayStandards</SubTitleText>
        <CenterBodyText>
          At PayStandards, trust is foundational to everything we do. We deploy industry-leading expertise, technology,
          and safeguards. So, you can rest-easy knowing you have our support every step of the way.
        </CenterBodyText>
      </CenterWrapper>
      <CenterNoMarginWrapper>
        <ThreeColFlexWrapper>
          <ThreeColWrapper>
            <Medal />
            <SecondaryTitleText>Compensation experts</SecondaryTitleText>
            <SecondThreeColBodyText>
              We are a team of leading compensation practitioners and researchers. You can feel confident that you’re
              being supported by the top compensation professionals.
            </SecondThreeColBodyText>
            <LearnMoreText onClick={() => goTo('/demo')}>Learn More</LearnMoreText>
          </ThreeColWrapper>
          <ThreeColWrapper>
            <LightBulb />
            <SecondaryTitleText>Innovative technology</SecondaryTitleText>
            <SecondThreeColBodyText>
              We are a technology company at our core. The insights we provide you are software-driven to empower you to
              reward more efficiently and effectively.
            </SecondThreeColBodyText>
            <LearnMoreText onClick={() => goTo('/demo')}>Learn More</LearnMoreText>
          </ThreeColWrapper>
          <ThreeColWrapper>
            <CheckShield />
            <SecondaryTitleText>Security and privacy</SecondaryTitleText>
            <SecondThreeColBodyText>
              We take data security seriously. PayStandards is SOC 2 and GDPR compliant and takes the necessary
              precautions to keep your data safe.
            </SecondThreeColBodyText>
            <LearnMoreText onClick={() => goTo('/security-privacy')}>Learn More</LearnMoreText>
          </ThreeColWrapper>
        </ThreeColFlexWrapper>
      </CenterNoMarginWrapper>
      {/* <NameWrapper>
        <LeftArrowWrapper>
          <LeftArrow onClick={decSlideStatus} />
        </LeftArrowWrapper>
        <RightArrowWrapper>
          <RightArrow onClick={incSlideStatus} />
        </RightArrowWrapper>
        <Profile slideStatus={slideStatus} />
      </NameWrapper> */}
      {/* {window.matchMedia(device.mobileM).matches && (
        <SubscribeWrapper>
          <EmailIconInstance />
          <SubscribeTitle>Join the growing PayStandards community and read new posts first.</SubscribeTitle>
          <SubscribeTextField>
            <SubscribeTextFieldInput placeholder="Email Address" />
          </SubscribeTextField>
          <SubscribeButton>Subscribe</SubscribeButton>
        </SubscribeWrapper>
      )} */}
      {/* <FootnoteWrapper>
        Note: Pay gap reduction and cost effectiveness model estimates above are based on simulated data.
      </FootnoteWrapper> */}
    </Wrapper>
  );
}

export default Home;
