import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import {
  RestIcon,
  TransitIcon,
  SecretIcon,
  ScanningIcon,
  SOCLogo,
  GDPRLogo,
  HandshakeImage,
  ComplianceIcon,
  DPAIcon,
} from '../../icons';
import { device } from '../../util';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;

  @media ${device.mobileM} {
    padding: 24px 16px;
  }

  @media ${device.tablet} {
    padding: 40px 32px;
  }

  @media ${device.laptop} {
    padding: 120px 96px;
  }
`;

const Title = styled.div`
  color: #283167;

  text-align: center;
  font-family: Open Sans;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
`;

const BlueTitle = styled(Title)`
  color: #2a7fef;
`;

const Subtitle = styled(Title)`
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  margin-top: 16px;
  max-width: 475px;
`;

const ShadowWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #f8f8f8;
  box-sizing: border-box;

  @media ${device.mobileM} {
    width: calc(100% + 32px);
    padding: 40px 16px;
    margin-top: 40px;
  }
  @media ${device.tablet} {
    width: calc(100% + 64px);
    padding: 64px 32px;
    margin-top: 64px;
  }
  @media ${device.laptop} {
    width: calc(100% + 192px);
    padding: 64px 96px;
    margin-top: 64px;
  }
`;

const GovernanceFlexWrapper = styled.div`
  display: flex;
  gap: 24px;
  align-items: flex-end;
  margin-top: 16px;

  @media ${device.mobileM} {
    flex-direction: column;
  }
  @media ${device.tablet} {
    flex-direction: column;
  }
  @media ${device.laptop} {
    flex-direction: row;
  }
`;

const LeftColWrapper = styled.div``;

const RightColWrapper = styled.div``;

const EnterpriseLeftColWrapper = styled.div`
  flex: 1.7;
  display: flex;
  flex-direction: column;
`;

const EnterpriseRightColWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const CardsTag = styled(Title)`
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  text-align: left;
`;

const CardsTitle = styled.div`
  color: #283167;
  font-family: Open Sans;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%; /* 44.8px */
`;

const BlueCardsTitle = styled(CardsTitle)`
  color: #2a7fef;
  display: inline;
`;

const CardsSubtitle = styled.div`
  color: var(--blue-900, #17375e);
  text-align: left;
  font-family: Open Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  margin-top: 32px;
`;

const CardWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
  gap: 20px;
  flex: 1;

  @media ${device.mobileM} {
    flex-direction: column;
  }
  @media ${device.tablet} {
    flex-direction: row;
  }
  @media ${device.laptop} {
    flex-direction: row;
  }
`;

const CardContentWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 20px;
  border-radius: 12px;
  background: #fff;
  padding: 32px;
`;

const EnterpriseCardContentWrapper = styled(CardContentWrapper)`
  justify-content: space-between;
`;

const CardTitle = styled.div`
  color: #283167;
  font-family: Open Sans;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; /* 33.6px */
`;

const CardSubtitle = styled.div`
  color: #283167;
  font-family: Open Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 22.4px */
`;

const Compliance = styled.div`
  color: #283167;
  font-family: Open Sans;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
  margin-top: 64px;
`;

const BlueCompliance = styled(Compliance)`
  color: #2a7fef;
  display: inline;
`;

const ComplianceImagesWrapper = styled.div`
  display: flex;
  margin-top: 20px;
  margin-bottom: 20px;
  gap: 10px;
`;

const ComplianceImageWrapper = styled.img`
  width: 100px;
  height: 100px;
`;

const DataProtectionWrapper = styled.div`
  display: flex;

  @media ${device.mobileM} {
    flex-direction: column;
    gap: 32px;
  }

  @media ${device.tablet} {
    flex-direction: column;
    gap: 40px;
  }

  @media ${device.laptop} {
    flex-direction: row;
    gap: 56px;
  }
`;

const DataProtectionSectionWrapper = styled.div`
  flex: 1;
`;
const DataProtectionTitle = styled.div`
  color: #283167;
  text-align: center;
  font-family: Open Sans;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; /* 44.8px */
  text-align: left;
  margin-bottom: 25px;
  width: 100%;

  @media ${device.mobileM} {
    margin-top: 64px;
  }

  @media ${device.tablet} {
    margin-top: 64px;
  }

  @media ${device.laptop} {
    margin-top: 100px;
  }
`;

const Blue = styled.div`
  color: #2a7fef;
  display: inline;
`;

const DataProtectionSectionHeader = styled.div`
  display: flex;
  gap: 15px;
  margin-bottom: 25px;
  width: 100%;
`;

const RestIconInstance = styled(RestIcon)`
  width: 36px;
  height: 36px;
`;

const TransitIconInstance = styled(TransitIcon)`
  width: 36px;
  height: 36px;
`;

const SecretIconInstance = styled(SecretIcon)`
  width: 36px;
  height: 36px;
`;

const DataProtectionSectionTitle = styled.div`
  color: #283167;

  text-align: center;
  font-family: Open Sans;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; /* 33.6px */
  text-align: center;

  text-wrap: nowrap;
`;

const DataProtectionParagraph = styled.div`
  color: #283167;
  font-family: Open Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 22.4px */
  text-align: left;
  width: 100%;
`;

const DividerInstance = styled.div`
  width: 100%;
  height: 1px;
  background: rgba(0, 0, 0, 0.2);
  margin-top: 80px;
`;

const ProductSecurityWrapper = styled.div`
  display: flex;

  @media ${device.mobileM} {
    flex-direction: column;
    gap: 0px;
  }

  @media ${device.tablet} {
    flex-direction: row;
    gap: 20px;
  }

  @media ${device.laptop} {
    flex-direction: row;
    gap: 20px;
  }
`;

const LineBreak = styled.div`
  margin-top: 20px;
`;

const ProductSecuritySectionTitle = DataProtectionSectionTitle;
const ProductSecurityParagraph = DataProtectionParagraph;
const EnterpriseSecurityTitle = styled(DataProtectionTitle)`
  margin-top: 0;
`;
const ProductSecuritySectionHeader = styled(DataProtectionSectionHeader)``;
const ScanningIconInstance = styled(ScanningIcon)``;
const ProductSecurityCard = styled.div`
  color: #283167;
  font-family: Open Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 22.4px */
  flex: 1;

  margin-top: 15px;
  padding: 32px 24px 32px 24px;
  border-radius: 12px;
  border: 1px solid #0000001a;
`;

const EnterpriseSecurityWrapper = styled(ShadowWrapper)``;

const VendorSecurityCard = styled(ProductSecurityCard)`
  color: #283167;
  font-family: Open Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%; /* 22.4px */
  flex: none;
`;

const HandshakeImageWrapper = styled.img`
  margin-left: auto;
  margin-right: auto;
  max-width: 400px;
  max-height: 400px;
  width: 100%;
  height: 100%;
`;

const EnterpriseFlexWrapper = styled.div`
  display: flex;
  align-items: stretch;

  @media ${device.mobileM} {
    flex-direction: column;
  }

  @media ${device.tablet} {
    flex-direction: column;
  }

  @media ${device.laptop} {
    flex-direction: row;
    gap: 35px;
  }
`;

const DataPrivacyWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;

  @media ${device.mobileM} {
    flex-direction: column;
    gap: 32px;
    margin-top: 32px;
  }

  @media ${device.tablet} {
    flex-direction: column;
    gap: 40px;
    margin-top: 32px;
  }

  @media ${device.laptop} {
    flex-direction: row;
    gap: 64px;
    margin-top: 54px;
  }
`;

const DataPrivacySubtitle = styled.div`
  color: #283167;
  font-family: Open Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 22.4px */
  text-align: left;
  width: 100%;
`;

const DataPrivacyBlock = styled.div`
  display: flex;
  margin-top: 20px;
  align-items: center;
  gap: 20px;
  flex: 1;
`;

const DataPrivacyBlockContent = styled.div`
  display: flex;
  flex-direction: column;
`;

const DataPrivacyBlockTitle = styled.div`
  color: #283167;

  font-family: Open Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; /* 22.4px */
`;

const DataPrivacyBlockSubtitle = styled.div`
  color: #283167;
  font-family: Open Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  margin-top: 15px;
`;

const ComplianceIconInstance = styled.img`
  width: 48px;
  height: 48px;
`;

const DPAIconInstance = styled.img`
  width: 48px;
  height: 48px;
`;

const EpilogueWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;

  @media ${device.mobileM} {
    margin-top: 64px;
  }

  @media ${device.tablet} {
    margin-top: 80px;
  }

  @media ${device.laptop} {
    margin-top: 154px;
  }
`;

const EpilogueHeader = styled.div`
  color: #283167;

  text-align: center;
  font-family: Open Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 22.4px */

  text-align: center;
`;

const EpilogueTitle = styled.div`
  color: #283167;
  font-family: Open Sans;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  margin-top: 30px;
  text-align: center;
`;

const EpilogueSubtitle = styled.div`
  color: #283167;
  font-family: Open Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  margin-top: 30px;
  text-align: center;
`;

function SecurityPrivacy() {
  const navigate = useNavigate();
  const goTo = (path: string) => {
    navigate(path);
  };
  return (
    <Wrapper>
      <Title>Security and privacy</Title>
      <BlueTitle>at PayStandards</BlueTitle>
      <Subtitle>
        Security is at the heart of what we do—our security and compliance posture is critical to our business.
      </Subtitle>
      <ShadowWrapper>
        <CardsTag>Governance</CardsTag>
        <GovernanceFlexWrapper>
          <LeftColWrapper>
            <CardsTitle>
              PayStandards’ Security and Privacy teams establish policies and controls, monitor compliance with those
              controls, and <BlueCardsTitle>prove our security and compliance</BlueCardsTitle> to third-party auditors.
            </CardsTitle>
            <CardsSubtitle>Our policies are based on the following foundational principles:</CardsSubtitle>
            <CardWrapper>
              <CardContentWrapper>
                <CardTitle>01</CardTitle>
                <CardSubtitle>
                  Access should be limited to only those with a legitimate business need and granted based on the
                  principle of least privilege.
                </CardSubtitle>
              </CardContentWrapper>
              <CardContentWrapper>
                <CardTitle>02</CardTitle>
                <CardSubtitle>
                  Security controls should be implemented and layered according to the principle of defense-in-depth.
                </CardSubtitle>
              </CardContentWrapper>
            </CardWrapper>
            <CardWrapper>
              <CardContentWrapper>
                <CardTitle>03</CardTitle>
                <CardSubtitle>
                  Security controls should be applied consistently across all areas of the enterprise.
                </CardSubtitle>
              </CardContentWrapper>
              <CardContentWrapper>
                <CardTitle>04</CardTitle>
                <CardSubtitle>
                  The implementation of controls should be iterative, continuously maturing across the dimensions of
                  improved effectiveness, increased auditability, and decreased friction.
                </CardSubtitle>
              </CardContentWrapper>
            </CardWrapper>
          </LeftColWrapper>
          <RightColWrapper>
            <CardContentWrapper>
              <CardTitle>Security and Compliance at PayStandards</CardTitle>
              <CardSubtitle>
                PayStandards maintains a SOC 2 Type I attestation and a GDPR compliance certification. Our SOC 2 Type I
                report and GDPR certificates are available on our Trust Report.
              </CardSubtitle>
              <Compliance>
                <BlueCompliance>PayStandards maintains</BlueCompliance> compliance with
              </Compliance>
              <ComplianceImagesWrapper>
                <ComplianceImageWrapper src={SOCLogo} />
                <ComplianceImageWrapper src={GDPRLogo} />
              </ComplianceImagesWrapper>
            </CardContentWrapper>
          </RightColWrapper>
        </GovernanceFlexWrapper>
      </ShadowWrapper>
      <DataProtectionTitle>
        Data <Blue>protection</Blue>
      </DataProtectionTitle>
      <DataProtectionWrapper>
        <DataProtectionSectionWrapper>
          <DataProtectionSectionHeader>
            <RestIconInstance />
            <DataProtectionSectionTitle>
              Data in <Blue>rest</Blue>
            </DataProtectionSectionTitle>
          </DataProtectionSectionHeader>
          <DataProtectionParagraph>
            All datastores with customer data, in addition to S3 buckets, are encrypted at rest. Sensitive collections
            and tables also use row-level encryption. <LineBreak />
            This means the data is encrypted even before it hits the database so that neither physical access, nor
            logical access to the database, is enough to read the most sensitive information.
          </DataProtectionParagraph>
        </DataProtectionSectionWrapper>
        <DataProtectionSectionWrapper>
          <DataProtectionSectionHeader>
            <TransitIconInstance />
            <DataProtectionSectionTitle>
              Data in <Blue>transit</Blue>
            </DataProtectionSectionTitle>
          </DataProtectionSectionHeader>
          <DataProtectionParagraph>
            PayStandards uses TLS 1.2 or higher everywhere data is transmitted over potentially insecure networks. We
            also use features such as HSTS (HTTP Strict Transport Security) to maximize the security of our data in
            transit. Server TLS keys and certificates are managed by AWS and deployed via Application Load Balancers.
          </DataProtectionParagraph>
        </DataProtectionSectionWrapper>
        <DataProtectionSectionWrapper>
          <DataProtectionSectionHeader>
            <SecretIconInstance />
            <DataProtectionSectionTitle>
              Secret <Blue>management</Blue>
            </DataProtectionSectionTitle>
          </DataProtectionSectionHeader>
          <DataProtectionParagraph>
            Encryption keys are managed via AWS Key Management System (KMS). KMS stores key material in Hardware
            Security Modules (HSMs), which prevents direct access by any individuals, including employees of Amazon &
            PayStandards. The keys stored in HSMs are used for encryption and decryption via Amazon’s KMS APIs.
            <LineBreak />
            Application secrets are encrypted and stored securely via AWS Secrets Manager and Parameter Store, and
            access to these values is strictly limited.
          </DataProtectionParagraph>
        </DataProtectionSectionWrapper>
      </DataProtectionWrapper>
      <DividerInstance />
      <DataProtectionTitle>
        Product <Blue>security</Blue>
      </DataProtectionTitle>
      <ProductSecuritySectionHeader>
        <ScanningIconInstance />
        <ProductSecuritySectionTitle>
          Vulnerability <Blue>Scanning</Blue>
        </ProductSecuritySectionTitle>
      </ProductSecuritySectionHeader>
      <ProductSecurityParagraph>
        PayStandards requires vulnerability scanning at key stages of our Secure Development Lifecycle (SDLC):
      </ProductSecurityParagraph>
      <ProductSecurityWrapper>
        <ProductSecurityCard>
          Static analysis (SAST) testing of code during pull requests and on an ongoing basis
        </ProductSecurityCard>
        <ProductSecurityCard>
          Software composition analysis (SCA) to identify known vulnerabilities in our software supply chain
        </ProductSecurityCard>
        <ProductSecurityCard>Network vulnerability scanning on a period basis</ProductSecurityCard>
      </ProductSecurityWrapper>
      <ProductSecurityWrapper>
        <ProductSecurityCard>
          Malicious dependency scanning to prevent the introduction of malware into our software supply chain
        </ProductSecurityCard>
        <ProductSecurityCard>Dynamic analysis (DAST) of running applications</ProductSecurityCard>
        <ProductSecurityCard>
          External attack surface management (EASM) continuously running to discover new external-facing assets
        </ProductSecurityCard>
      </ProductSecurityWrapper>
      <EnterpriseSecurityWrapper>
        <EnterpriseSecurityTitle>
          Enterprise <Blue>security</Blue>
        </EnterpriseSecurityTitle>
        <EnterpriseFlexWrapper>
          <EnterpriseLeftColWrapper>
            <CardWrapper>
              <CardContentWrapper>
                <CardTitle>
                  Endpoint <Blue>protection</Blue>
                </CardTitle>
                <CardSubtitle>
                  All corporate devices are equipped with mobile device management software and anti-malware protection.
                  Endpoint security is monitored with 24/7/365 coverage. We use MDM software to enforce secure
                  configuration of endpoints, such as disk encryption, screen lock configuration, and software updates.
                </CardSubtitle>
              </CardContentWrapper>
            </CardWrapper>
            <CardWrapper>
              <CardContentWrapper>
                <CardTitle>
                  Secure <Blue>remote access</Blue>
                </CardTitle>
                <CardSubtitle>
                  PayStandards secures remote access to internal resources using AWS’ VPN platform. We also use
                  malware-blocking DNS servers to protect employees and their endpoints while browsing the internet.
                </CardSubtitle>
              </CardContentWrapper>
            </CardWrapper>
            <CardWrapper>
              <CardContentWrapper>
                <CardTitle>
                  Security <Blue>education</Blue>
                </CardTitle>
                <CardSubtitle>
                  PayStandards provides comprehensive security training to all employees upon onboarding and annually
                  through educational modules. In addition, all new employees attend a mandatory live onboarding session
                  centered around key security principles. All new engineers also attend a mandatory live onboarding
                  session focused on secure coding principles and practices. PayStandards’ security team shares regular
                  threat briefings with employees to inform them of important security and safety-related updates that
                  require special attention or action.
                </CardSubtitle>
              </CardContentWrapper>
            </CardWrapper>
            <CardWrapper>
              <CardContentWrapper>
                <CardTitle>
                  Identity and <Blue>access management</Blue>
                </CardTitle>
                <CardSubtitle>
                  PayStandards uses AWS to secure our identity and access management. We enforce the use of
                  phishing-resistant authentication factors, using Cognito exclusively wherever possible. <LineBreak />
                  PayStandards employees are granted access to applications based on their role, and automatically
                  deprovisioned upon termination of their employment. Further access must be approved according to the
                  policies set for each application.
                </CardSubtitle>
              </CardContentWrapper>
            </CardWrapper>
          </EnterpriseLeftColWrapper>
          <EnterpriseRightColWrapper>
            <CardWrapper>
              <EnterpriseCardContentWrapper>
                <CardTitle>
                  Vendor <Blue>security</Blue>
                </CardTitle>
                <CardSubtitle>
                  PayStandards uses a risk-based approach to vendor security. Factors which influence the inherent risk
                  rating of a vendor include:
                </CardSubtitle>
                <VendorSecurityCard>Integration with production environments</VendorSecurityCard>
                <VendorSecurityCard>Potential damage to the PayStandards brand</VendorSecurityCard>
                <VendorSecurityCard>Access to customer and corporate data</VendorSecurityCard>
                <CardSubtitle>
                  Once the inherent risk rating has been determined, the security of the vendor is evaluated in order to
                  determine a residual risk rating and an approval decision for the vendor.
                </CardSubtitle>
                <HandshakeImageWrapper src={HandshakeImage} />
              </EnterpriseCardContentWrapper>
            </CardWrapper>
          </EnterpriseRightColWrapper>
        </EnterpriseFlexWrapper>
      </EnterpriseSecurityWrapper>
      <DataProtectionTitle>
        Data <Blue>privacy</Blue>
      </DataProtectionTitle>
      <DataPrivacySubtitle>
        At PayStandards, data privacy is a first-class priority—we strive to be trustworthy stewards of all sensitive
        data.
      </DataPrivacySubtitle>
      <DataPrivacyWrapper>
        <DataPrivacyBlock>
          <ComplianceIconInstance src={ComplianceIcon} />
          <DataPrivacyBlockContent>
            <DataPrivacyBlockTitle>
              Regulatory <Blue>compliance</Blue>
            </DataPrivacyBlockTitle>
            <DataPrivacyBlockSubtitle>
              PayStandards evaluates updates to regulatory and emerging frameworks continuously to evolve our program.
            </DataPrivacyBlockSubtitle>
          </DataPrivacyBlockContent>
        </DataPrivacyBlock>
        <DataPrivacyBlock>
          <DPAIconInstance src={DPAIcon} />
          <DataPrivacyBlockContent>
            <DataPrivacyBlockTitle>Privacy Policy</DataPrivacyBlockTitle>
            <DataPrivacyBlockSubtitle>
              View PayStandards’{' '}
              <Blue onClick={() => goTo('/privacy-policy')} style={{ cursor: 'pointer' }}>
                Privacy Policy
              </Blue>{' '}
            </DataPrivacyBlockSubtitle>
          </DataPrivacyBlockContent>
        </DataPrivacyBlock>
      </DataPrivacyWrapper>
      <EpilogueWrapper>
        <EpilogueHeader>Responsible Disclosure</EpilogueHeader>
        <EpilogueTitle>
          Looking to report a <Blue>security concern?</Blue>
        </EpilogueTitle>
        <EpilogueSubtitle>
          Please visit our{' '}
          <Blue onClick={() => goTo('/contactus')} style={{ cursor: 'pointer' }}>
            Contact Us
          </Blue>{' '}
          page.
        </EpilogueSubtitle>
      </EpilogueWrapper>
    </Wrapper>
  );
}

export default SecurityPrivacy;
