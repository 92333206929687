import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import ReactModal from 'react-modal';
import { GreyCross, MailIcon, PhoneIcon, TripleCircleGreenCheck } from '../../icons';
import { device } from '../../util';
import sendContactEmail from '../../network';

const Wrapper = styled.div`
  @media ${device.mobileM} {
    display: flex;
    flex-direction: column;
    background: #fff;
    align-items: center;
    padding: 24px 16px;
  }

  @media ${device.tablet} {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    gap: 40px;
    padding: 40px 32px;
  }

  @media ${device.laptop} {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    gap: 96px;
    padding: 80px 120px;
  }
`;

const SubWrapper = styled.div``;

const ContactWrapper = styled(SubWrapper)`
  @media ${device.mobileM} {
    width: 375px;
    padding-left: 16px;
    padding-right: 16px;
    box-sizing: border-box;
    margin-bottom: 64px;
  }

  @media ${device.tablet} {
    padding: 0px;
    flex: 1;
  }

  @media ${device.laptop} {
    padding: 0px;
    flex: 1;
  }
`;

const ConnectWrapper = styled(SubWrapper)`
  @media ${device.mobileM} {
    width: 375px;
    padding-left: 16px;
    padding-right: 16px;
    box-sizing: border-box;
    margin-bottom: 64px;
  }

  @media ${device.tablet} {
    padding: 0px;
    flex: 1;
  }

  @media ${device.laptop} {
    padding: 0px;
    flex: 1;
  }
`;

// const SubscribeWrapper = styled(SubWrapper)`
//   @media ${device.mobileM} {
//     display: flex;
//     flex-direction: column;
//     width: 343px;
//     margin-left: 16px;
//     margin-right: 16px;
//     box-sizing: border-box;
//     border-radius: 20px;
//     background: #f8f8f8;
//     padding: 48px 24px;
//     align-items: center;
//     margin-bottom: 65px;
//   }

//   @media ${device.tablet} {
//     width: calc(100% - 64px);
//     flex-direction: row;
//     justify-content: space-between;
//   }

//   @media ${device.laptop} {
//     width: calc(100% - 196px);
//   }
// `;

const ContactMethodWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

const ContactMethod = styled.div`
  border: 1px solid rgba(0, 0, 0, 0.1);
  width: fit-content;
  padding: 16px 24px;
  display: flex;
  gap: 8px;
`;

const Text = styled.div`
  font-family: Open Sans;
  font-style: normal;
  line-height: 140%;
`;

const Body = styled(Text)`
  font-weight: 400;
  font-size: 16px;
  color: #283167;
  text-align: justify;
  text-justify: distribute-all-lines;
  margin-bottom: 50px;
`;

const MethodText = styled(Text)`
  font-weight: 400;
  font-size: 16px;
  color: #283167;
`;

const Title = styled(Text)`
  font-weight: 700;
  font-size: 32px;
  color: #283167;
  margin-bottom: 24px;
`;

const ContactTitle = styled(Title)`
  @media ${device.mobileM} {
    font-size: 32px;
  }

  @media ${device.tablet} {
    font-size: 48px;
  }
`;

const ConnectInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 34px;
`;

const ConnectInput = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`;

const ConnectText = styled(Text)`
  font-weight: 400;
  font-size: 15px;
  color: #283167;
  line-height: 20px;
`;

const ConnectInputField = styled.textarea`
  border-radius: 8px;
  border: 1px solid #e0f0ff;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  color: var(--gray-500, #667085);
  font-family: Open Sans;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  padding: 10px 14px;
  resize: none;
`;

const SubmitButton = styled.div`
  width: fit-content;
  padding: 10px 32px;
  color: #fff;
  font-family: Open Sans;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  border-radius: 6px;
  border: 1px solid #2a7fef;
  background: #2a7fef;

  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  margin-bottom: 32px;
  cursor: pointer;

  &:hover {
    background-color: #3a7bcf;
  }
`;

const InfoText = styled(Text)`
  color: #667085;
  font-size: 15px;
  font-weight: 400;
`;

const AboutUsLink = styled.a`
  color: #2a7fef;
  font-family: Open Sans;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  text-decoration-line: underline;
  cursor: pointer;
`;

const HintText = styled(Text)<{ enable?: boolean }>`
  color: #ca1d1c;
  font-style: italic;
  margin-top: -10px;
  font-size: 15px;
  display: ${(props) => (props.enable ? 'block' : 'none')};
`;

const ModalTitle = styled(Text)`
  color: #283167;
  text-align: center;
  font-size: 24px;
  font-weight: 600;
  line-height: 28px; /* 116.667% */
  margin-top: 20px;
`;

const ModalBody = styled(Text)`
  color: #283167;
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  line-height: 140%; /* 22.4px */
  width: 70%;
  margin-top: 8px;
`;

const ModalBackHome = styled(Text)`
  color: #2a7fef;
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  line-height: 140%; /* 22.4px */
  cursor: pointer;
  margin-top: 32px;
`;

const GreenCheckInstance = styled(TripleCircleGreenCheck)`
  min-width: 48px;
  min-height: 48px;
`;

const GreyCrossInstance = styled(GreyCross)`
  position: absolute;
  cursor: pointer;
  width: fit-content;
  margin-left: calc(min(400px, 90vw) - 48px);
`;

// const IconWrapper = styled.div`;
//   width: 64px;
//   height: 64px;
//   border-radius: 140px;
//   background: #2a7fef;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   margin-bottom: 16px;
// `;

// const JoinText = styled(Text)`
//   color: #283167;
//   font-size: 24px;
//   font-weight: 700;
//   text-align: center;
//   margin-bottom: 24px;

//   @media ${device.tablet} {
//     text-align: left;
//     width: 48%;
//   }
// `;

// const JoinInput = styled(ConnectInputField)`
//   width: 80%;
//   margin-bottom: 16px;

//   @media ${device.tablet} {
//     width: 25%;
//   }
// `;

// const JoinButton = styled(SubmitButton)`
//   width: 80%;
//   padding: 10px 16px;
//   text-align: center;

//   @media ${device.tablet} {
//     width: 8%;
//     margin-bottom: 16px;
//   }
// `;

// const WhiteMailIcon = styled(MailIcon)`
//   color: #fff;
// `;

function ContactUs() {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [hearAboutUs, setHearAboutUs] = useState('');
  const [hintEnable, setHintEnable] = useState([false, false]);
  const [modalIsOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const goTo = (path: string) => {
    navigate(path);
  };

  const onSendSuccess = () => {
    setIsOpen(true);
    setEmail('');
    setMessage('');
    setHearAboutUs('');
  };

  const onSendFailure = () => {
    // eslint-disable-next-line no-alert
    window.alert('Something went wrong. Please try again.');
  };

  const checkAllFields = () => {
    const table = [false, false, false, false, false];
    if (email === '') {
      table[0] = true;
    }
    if (message === '') {
      table[1] = true;
    }
    setHintEnable(table);
    if (table.every((value) => value === false)) {
      const currentTime = new Date();
      const dateString = currentTime.toLocaleDateString();
      const timeString = currentTime.toLocaleTimeString();
      const dateTimeString = `${dateString} ${timeString}`;
      sendContactEmail(email, message, hearAboutUs, dateTimeString, onSendSuccess, onSendFailure);
    }
  };

  return (
    <Wrapper>
      <ReactModal
        isOpen={modalIsOpen}
        ariaHideApp={false}
        style={{
          overlay: { zIndex: 10000, display: 'flex', alignItems: 'center', justifyContent: 'center' },
          content: {
            width: 'min(400px, 90vw)',
            position: 'static',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            padding: '24px',
            boxSizing: 'border-box',
            borderRadius: '12px',
            background: '#FFF',
            boxShadow: '0px 8px 8px -4px rgba(16, 24, 40, 0.03), 0px 20px 24px -4px rgba(16, 24, 40, 0.08)',
            border: 'none',
          },
        }}
        onRequestClose={() => setIsOpen(false)}
      >
        <GreyCrossInstance onClick={() => setIsOpen(false)} />
        <GreenCheckInstance />
        <ModalTitle>Submitted</ModalTitle>
        <ModalBody>
          Thank you for reaching out! <br />
          Our team will get back to you.
        </ModalBody>
        <ModalBackHome onClick={() => goTo('/home')}>Back to Home</ModalBackHome>
      </ReactModal>
      <ContactWrapper>
        <ContactTitle>Contact Us</ContactTitle>
        <Body>
          See how the world’s largest and fastest-growing companies use PayStandards to attain and sustain pay equity.
          We’re here for you every step of the way.
        </Body>
        <ContactMethodWrapper>
          <ContactMethod>
            <MailIcon />
            <MethodText>partner@paystandards.com</MethodText>
          </ContactMethod>
          <ContactMethod>
            <MailIcon />
            <MethodText>help@paystandards.com</MethodText>
          </ContactMethod>
          <ContactMethod>
            <PhoneIcon />
            <MethodText>+1 (631) 546-0584</MethodText>
          </ContactMethod>
        </ContactMethodWrapper>
      </ContactWrapper>
      <ConnectWrapper>
        <Title>Let’s Connect</Title>
        <ConnectInputWrapper>
          <ConnectInput>
            <ConnectText>Email</ConnectText>
            <ConnectInputField placeholder="Email*" rows={1} value={email} onChange={(e) => setEmail(e.target.value)} />
          </ConnectInput>
          <HintText enable={hintEnable[0]}>Please complete this required field.</HintText>
          <ConnectInput>
            <ConnectText>Message</ConnectText>
            <ConnectInputField
              placeholder="Enter your message... "
              rows={5}
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            />
          </ConnectInput>
          <HintText enable={hintEnable[1]}>Please complete this required field.</HintText>
          <ConnectInput>
            <ConnectText>How did you hear about us? (Optional)</ConnectText>
            <ConnectInputField
              placeholder="Let us know..."
              rows={1}
              value={hearAboutUs}
              onChange={(e) => setHearAboutUs(e.target.value)}
            />
          </ConnectInput>
        </ConnectInputWrapper>
        <SubmitButton onClick={checkAllFields}>Submit</SubmitButton>
        <InfoText>
          To learn more about PayStandards and company, please see our{' '}
          <AboutUsLink onClick={() => goTo('/home')}>Home</AboutUsLink> page
        </InfoText>
      </ConnectWrapper>
      {/* <SubscribeWrapper>
        <IconWrapper>
          <WhiteMailIcon />
        </IconWrapper>
        <JoinText>Join the growing PayStandards community and read new posts first.</JoinText>
        <JoinInput placeholder="Email Address" rows={1} />
        <JoinButton>Subscribe</JoinButton>
      </SubscribeWrapper> */}
    </Wrapper>
  );
}

export default ContactUs;
