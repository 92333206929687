import styled from 'styled-components';
import { device } from '../../util';
import { AuditProductImg } from '../../icons';

const Wrapper = styled.div`
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;

  @media ${device.mobileM} {
    padding: 24px 16px;
  }

  @media ${device.tablet} {
    padding: 40px 32px;
  }

  @media ${device.laptop} {
    padding: 80px 69px;
  }
`;
const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const Title = styled.div`
  color: #283167;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  text-align: center;
  text-wrap: nowrap;

  @media ${device.mobileM} {
    font-size: min(32px, 7vw);
  }

  @media ${device.tablet} {
    font-size: 42px;
  }

  @media ${device.laptop} {
    font-size: 42px;
  }
`;

const BodyText = styled.div`
  color: var(--grey-700, #575d66);
  text-align: center;

  /* Website/P */
  font-family: Open Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  max-width: 640px;
  margin-top: 16px;
`;

// const RequestDemoButton = styled.div`
//   padding: 10px 16px;
//   background: #2a7fef;
//   border: 1px solid #2a7fef;
//   box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
//   border-radius: 6px;
//   cursor: pointer;
//   color: #ffffff;
//   font-weight: 700;
//   font-size: 15px;
//   margin-top: 24px;

//   &:hover {
//     background-color: #3a7bcf;
//   }
// `;

// const ThreeColFlexWrapper = styled.div`
//   display: flex;
//   justify-content: center;
//   @media ${device.mobileM} {
//     flex-direction: column;
//   }
//   @media ${device.tablet} {
//     flex-direction: row;
//     gap: 32px;
//   }
//   @media ${device.laptop} {
//     flex-direction: row;
//   }
// `;

// const ThreeColWrapper = styled.div`
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   flex: 1;
//   @media ${device.mobileM} {
//     margin-top: 24px;
//   }
//   @media ${device.tablet} {
//     margin-top: 48px;
//   }
//   @media ${device.laptop} {
//     margin-top: 100px;
//   }
// `;

// const Text = styled.div`
//   font-family: 'Open Sans';
//   font-style: normal;
//   font-weight: 700;
//   line-height: 140%;
//   color: #283167;
//   text-align: center;
// `;

// const SecondaryTitleText = styled(Text)`
//   margin-top: 24px;
//   font-size: 24px;
//   @media ${device.mobileM} {
//     height: auto;
//   }
//   @media ${device.tablet} {
//     height: 68px;
//   }
//   @media ${device.laptop} {
//     height: 68px;
//   }
// `;

// const ThreeColBodyText = styled(Text)`
//   height: 68px;
//   margin-top: 10px;
//   margin-bottom: 16px;
//   font-weight: 400;
//   font-size: 16px;
//   @media ${device.mobileM} {
//     height: auto;
//   }
//   @media ${device.tablet} {
//     height: 68px;
//   }
//   @media ${device.laptop} {
//     height: 68px;
//   }
// `;

const ImgWrapper = styled.div`
  @media ${device.mobileM} {
    margin-top: 24px;
  }

  @media ${device.tablet} {
    margin-top: 48px;
  }

  @media ${device.laptop} {
    margin-top: 90px;
  }
`;

const ComputerImg = styled(AuditProductImg)`
  max-width: 100%;
  height: auto;
`;

function AuditProduct() {
  // const navigate = useNavigate();
  // const goTo = (path: string) => {
  //   navigate(path);
  // };
  return (
    <Wrapper>
      <TitleWrapper>
        <Title>PayStandards Audit&#8482;</Title>
      </TitleWrapper>
      <BodyText>
        Data-driven pay decisions that close pay gaps, significantly and cost-effectively, as part of the annual
        compensation review process.
      </BodyText>
      {/* <RequestDemoButton onClick={() => goTo('/demo')}>Request Demo</RequestDemoButton> */}
      <ImgWrapper>
        <ComputerImg />
      </ImgWrapper>
      {/* <ThreeColFlexWrapper>
        <ThreeColWrapper>
          <BlueCheckCircleSquare />
          <SecondaryTitleText>Resolve Pay Disparities</SecondaryTitleText>
          <ThreeColBodyText>Isolate and reduce illegitimate drivers of pay</ThreeColBodyText>
        </ThreeColWrapper>
        <ThreeColWrapper>
          <OrangeDollarCircleSquare />
          <SecondaryTitleText>Optimize Pay Adjustments</SecondaryTitleText>
          <ThreeColBodyText>Allocate your pay equity adjustments effectively to maximize your value</ThreeColBodyText>
        </ThreeColWrapper>
        <ThreeColWrapper>
          <GreenHandshakeCircleSquare />
          <SecondaryTitleText>Put Performance First</SecondaryTitleText>
          <ThreeColBodyText>Leverage your predicted pay model to ensure you are rewarding effectively</ThreeColBodyText>
        </ThreeColWrapper>
      </ThreeColFlexWrapper> */}
    </Wrapper>
  );
}

export default AuditProduct;
